export default () => ({
	open: false,
	shouldHideButton: false, // Initially assume the button should be shown

	init() {
		this.updateButtonVisibility();
		this.updateAriaLabel(); // Initialize the aria-label
	},

	updateButtonVisibility() {
		const content = this.$refs.commentContent;

		if (content.clientHeight < 130) {
			this.shouldHideButton = true;
			this.open = true; // Ensure that content is open if button is hidden
		} else {
			this.shouldHideButton = false;
			this.open = false; // Ensure that content is closed if button is visible
		}
		this.updateAriaLabel(); // Update the aria-label after visibility is set
	},

	toggleContent() {
		if (!this.shouldHideButton) {
			this.open = !this.open;
			this.updateAriaLabel(); // Update the aria-label on toggle
		}
	},

	updateAriaLabel() {
		const button = this.$refs.btnToggle;
		const ariaOpen = button.getAttribute('data-aria-open');
		const ariaClose = button.getAttribute('data-aria-close');

		// Update the aria-label based on the state of `open`
		button.setAttribute('aria-label', this.open ? `${ariaClose}` : `${ariaOpen}`);
	}
});
