export function accordionDesktopDown() {
  var accordion = jQuery('.accordion--desktop-down');

  if (accordion.length > 0) {
    var accordionInstance = (function() {
      var settings = {
        speed: 400,
        oneOpen: false
      };

      return {
        init: function($settings) {
          jQuery(document).on("click", ".accordion--desktop-down__toggle", function(e) {
            e.preventDefault();
            accordionInstance.toggle(jQuery(this));
          });

          $.extend(settings, $settings);

          jQuery('.accordion--desktop-down.active').find('> .accordion--desktop-down__body').show();
        },
        toggle: function($this) {
          $this.attr("aria-expanded", function(_, attr) {
            return attr === "true" ? "false" : "true";
          });

          if (settings.oneOpen) {
            var activeAccordion = $this.closest('.accordion--desktop-down.active');
            if (activeAccordion.length && !$this.closest('.accordion--desktop-down').hasClass('active')) {
              activeAccordion.removeClass('active').find('.accordion--desktop-down__body').slideUp(settings.speed);
            }
          }

          $this.closest('.accordion--desktop-down').toggleClass('active').find('.accordion--desktop-down__body').stop().slideToggle(settings.speed);
        },
        destroy: function() {
          jQuery('.accordion--desktop-down').find('.accordion--desktop-down__toggle').removeAttr('aria-expanded');
          jQuery('.accordion--desktop-down').removeClass('active').find('.accordion--desktop-down__body').removeAttr('style');
        }
      };
    })();

    // Initialize accordion
    accordionInstance.init({ speed: 300, oneOpen: true });

    // Function to handle accordion destruction and reinitialization on window resize
    function handleAccordionState() {
      // if (window.matchMedia("(min-width: 768px)").matches) {
      //   jQuery('.accordion--desktop-down__toggle').prop('disabled', true);

      //   if (accordionInstance && !accordion.hasClass('active')) {
      //     accordionInstance.destroy();
      //   }
      // } else {
        jQuery('.accordion--desktop-down__toggle').prop('disabled', false);

        if (!accordionInstance) {
          accordionInstance.init({ speed: 300, oneOpen: true });
        } else {
          jQuery('.accordion--desktop-down__toggle').attr('aria-expanded', function() {
            return jQuery(this).closest('.accordion--desktop-down').hasClass('active') ? 'true' : 'false';
          });
        }
      // }
    }

    jQuery(window).on('load resize', handleAccordionState);
  }
}

export default accordionDesktopDown;