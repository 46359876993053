export function darkmodeScale() {
		// Constants
		const themeSelectors = document.querySelectorAll(".jsDarkmodeToggle");
		if (themeSelectors.length == 0) {
			return;
		}
		const storageKey = "colorThemeDarkMode";
		const lightThemeClass = "light-theme";
		const darkThemeClass = "dark-theme";
		const isDarkSystemTheme =
			window.matchMedia &&
			window.matchMedia("(prefers-color-scheme: dark)").matches;

		const navbrandImg = jQuery(
			".header__megamenu-extended .navbar__megamenu-extended .navbar__megamenu__header .navbar-brand img",
		);
		const footerbrandImg = jQuery("footer .logo img");
		const footerSubbrandImg = jQuery("footer .logo img[data-defaultSubLogo]");
		const favicon = jQuery("link[rel='icon']");
		// Get the theme preference (dark-theme, light-theme, or null)
		const getSelectedTheme = () => localStorage.getItem(storageKey);

		// Set theme helper
		const setSelectedTheme = (theme) => localStorage.setItem(storageKey, theme);

		// Functions to add/remove dark/light theme classes
		const applyDarkModeClasses = () => {
			document.body.classList.add(darkThemeClass);
			document.body.classList.remove(lightThemeClass);
			setDarkmodeLogo();
		};

		const applyLightModeClasses = () => {
			document.body.classList.add(lightThemeClass);
			document.body.classList.remove(darkThemeClass);
			setDefaultLogo();
		};

		// Handle theme toggle
		const handleThemeToggle = () => {
			const isDarkModeActive = document.body.classList.toggle(darkThemeClass);

			if (isDarkModeActive) {
				applyDarkModeClasses();
				setSelectedTheme(darkThemeClass);
			} else {
				applyLightModeClasses();
				setSelectedTheme(lightThemeClass);
			}

			themeSelectors.forEach((item) =>
				item.classList.toggle("active", isDarkModeActive),
			);
		};

		// Initialize the theme on mount
		const initializeTheme = () => {
			const selectedTheme = getSelectedTheme();
			if (selectedTheme === darkThemeClass) {
				applyDarkModeClasses();
				themeSelectors.forEach((item) => item.classList.add("active"));
			} else {
				applyLightModeClasses();
			}

			// Set favicon based on system dark mode preference
			setFaviconBasedOnSystemTheme();
		};

		// Set logos and favicon for dark mode
		function setDarkmodeLogo() {
			const darkmodeNavLogo = navbrandImg.attr("data-darkmodeLogo");
			navbrandImg.attr("src", darkmodeNavLogo);

			const darkmodeFooterLogo = footerbrandImg.attr("data-darkmodeLogo");
			footerbrandImg.attr("src", darkmodeFooterLogo);

			const darkmodeSubFooterLogo = footerSubbrandImg.attr(
				"data-darkmodeSubLogo",
			);
			footerSubbrandImg.attr("src", darkmodeSubFooterLogo);
		}

		// Set logos and favicon for light mode (default)
		function setDefaultLogo() {
			const defaultNavLogo = navbrandImg.attr("data-defaultLogo");
			navbrandImg.attr("src", defaultNavLogo);

			const defaultFooterLogo = footerbrandImg.attr("data-defaultLogo");
			footerbrandImg.attr("src", defaultFooterLogo);

			const subFooterLogo = footerSubbrandImg.attr("data-defaultSubLogo");
			footerSubbrandImg.attr("src", subFooterLogo);
		}

		// Set favicon based on system theme preference
		function setFaviconBasedOnSystemTheme() {
			if (isDarkSystemTheme) {
				const darkmodeFavicon = favicon.attr("data-darkmode");
				favicon.attr("href", darkmodeFavicon);
			} else {
				const defaultFavicon = favicon.attr("data-default");
				favicon.attr("href", defaultFavicon);
			}
		}

		// Event listener setup
		themeSelectors.forEach((button) => {
			button.addEventListener("click", handleThemeToggle);
		});

		initializeTheme();
	// Initialize on page load
}

export default darkmodeScale;
