export function videoYoutube() {

  if ( jQuery( ".video-youtube" ).length < 0 ) {
    return;
  }

  jQuery('.video__youtube').click(function() {
    var embedId = jQuery(this).data("embed");
    embedId += "?autoplay=1&rel=0";
    var iframe = document.createElement( "iframe" );
    iframe.setAttribute( "class", "video__youtube__iframe" );
    iframe.setAttribute( "frameborder", "0" );
    iframe.setAttribute( "allowfullscreen", "" );
    iframe.setAttribute( "src", "https://www.youtube.com/embed/" + embedId);
    iframe.setAttribute("allow", "autoplay; encrypted-media");
    jQuery(iframe).appendTo(jQuery(this));
  });

}

export default videoYoutube;
