/*
** Polyfill for browsers that don't support object-fit
** https://www.npmjs.com/package/object-fit-images
**
** Usage: img {font-family: $font-object-fit-cover;}
*/

export function objectFitImages() {

    var objectFitImages = require('object-fit-images');
    jQuery(document).ready(function () {
        objectFitImages();
    });
}

export default objectFitImages;
