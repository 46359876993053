export function unsaveButton(){
  if(document.querySelector(".js-unsave-button")){

    const unsaveButtonElements = document.querySelectorAll('.js-unsave-button');

    unsaveButtonElements.forEach(button => {
      button.addEventListener('click', () => {
        //remove page from local storage array
        let savedPages = JSON.parse(localStorage.getItem('savedPages'));
        let pageId = button.getAttribute('data-page-id');
        savedPages.splice(savedPages.indexOf(pageId), 1)
        localStorage.setItem('savedPages', JSON.stringify(savedPages))

        //remove card from page
        let card = button.closest('.card__list--title-link');
        card.parentNode.removeChild(card);
      })
    })
  }
}

export default unsaveButton;
