export function sbsysaccordion() {
	if (jQuery(".js-accordion-item-agenda").length == 0) {
		return;
	}
	jQuery(document).on("click", ".js-accordion-item-agenda", function (e) {
		var folderId = jQuery(this).data("listid");
		var pageUrl = jQuery(this).data("pageurl");
		var elementId = jQuery(this).data("elementid");
		var accordionBodyContents = jQuery(this)
			.closest(".js-accordion-item")
			.find(".accordion-body__contents");
		var hasList = accordionBodyContents.find(".list__links");
		if (hasList.length) {
			return;
		}
		var loadingSpinner = accordionBodyContents.find(".lds-default");
		if (loadingSpinner) {
			loadingSpinner.css("display", "inline-block");
		}
		var requestData = {
			folderId: folderId,
			pageUrl: pageUrl,
			elementId: elementId,
		};
		console.log(requestData);
		fetch(
			"/umbraco/surface/AgendaSurface/GetAgendaList?" +
				new URLSearchParams(requestData),
		)
			.then(function (response) {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.text();
			})
			.then(function (data) {
				var newData = data.trim();
				if (loadingSpinner) {
					loadingSpinner.css("display", "none");
				}
				console.log("data:", newData);
				accordionBodyContents.html(newData);
				accordionBodyContents.css("display", "block");
			})
			.catch(function () {
				console.log("Error in loaded comments");
				if (loadingSpinner) {
					loadingSpinner.css("display", "none");
				}
			});
	});
}
export default sbsysaccordion;
