/**
 * Service menu
 * Share by email
 */

export function serviceMenuShareByEmail() {
  // console.log('serviceMenuShareByEmail loaded');

  // Check if the service menu exists before proceeding
  if (jQuery(".service-menu").length === 0) {
    return;
  }

  // Handle form submission for email share (if form exists)
  // const form = document.getElementById("shareByEmailForm");
  // if (form) {
  //   form.addEventListener("submit", (e) => {
  //     e.preventDefault();

  //     const recipientEmail = document.getElementById("recipientEmail").value;
  //     const senderName = document.getElementById("senderName").value;
  //     const message = document.getElementById("message").value;

  //     const subject = `${senderName} via Client name has shared a page with you`;
  //     const body = `${message}\n\nLink to the page: ${window.location.href}`;

  //     const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  //     // Open the email client and populate the fields
  //     window.location.href = mailtoLink;

  //     // Hide the modal after sending the email (optional)
  //     const modal = document.getElementById("uniqueID-shareByEmail");
  //     if (modal) {
  //       $(modal).modal('hide');
  //     }
  //   });
  // }

  // Handle email share by link (mailto link)
  const emailShareButtons = document.querySelectorAll('.email-share-btn'); // Changed to querySelectorAll
  // console.log('Number of buttons found:', emailShareButtons.length); // Corrected the console log

  if (emailShareButtons.length > 0) {
    // console.log('Email Share Buttons found');

    emailShareButtons.forEach(button => {
      button.addEventListener("click", () => {
        // console.log("Email button clicked"); // Debugging log

        // Access the data attributes
        const subject = button.dataset.emailSubject || "Se denne side";
        const linkText = button.dataset.emailLinkText || "Link til siden:";
        const body = button.dataset.emailBody || `I thought you might be interested in this page: ${window.location.href}`;

        const pageUrl = window.location.href;

        // Use the URL in the email body
        const fullBody = `${body}\n\n${linkText} ${pageUrl}`;

        // Open the default email client with subject and body pre-filled
        window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(fullBody)}`;
      });
    });
  } else {
    // console.log('Email Share Button not found');
  }

}

export default serviceMenuShareByEmail;