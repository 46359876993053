export function parallax() {
  // console.log("1")

  // jQuery(window).on("scroll", function() {
  //   console.log("scroll")
  //   var parallax = jQuery(".parallax")
  //   var x = jQuery(this).scrollTop();
  //   jQuery(parallax).css('background-position','center '+parseInt(-x/5)+'px');
  //   // you can change the 0% to center ^
  // });
}

export default parallax;
