import { throttle } from "lodash-es";

export function tabpanelReworked(){
    if(document.querySelector('.tab-panel--container')){

        // function that modifies the url when button is clicked (only if tab component has ID)
        // the purpose of this is to construct the link, so users can copy paste it from the adress bar
        document.querySelectorAll('.tab-control').forEach(button => {
            button.addEventListener('click', () => {
                let buttons = Array.from(button.parentElement.parentElement.children);
                let active = buttons.indexOf(button.parentElement);
                let id = button.closest('.tab-panel--container').id
                if(id){
                    // Construct the URL with the updated parameters
                    let url = window.location.origin + window.location.pathname + '#' + id + '?activeTab=' + active;
                    
                    // Modify the URL in the address bar
                    window.history.replaceState(null, null, url);
                }
            })
        })

        function getActiveTabFromUrl(id) {
            // Get the current URL from the address bar
            const url = window.location.href;
          
            // Extract the fragment identifier (the part after the # symbol)
            const fragmentIndex = url.indexOf('#');
            const fragment = fragmentIndex !== -1 ? url.slice(fragmentIndex + 1) : '';
          
            // Extract the query parameters
            const queryParams = {};
            const queryParamsIndex = url.indexOf('?');
          
            if (queryParamsIndex !== -1) {
              const queryString = url.slice(queryParamsIndex + 1);
              const params = new URLSearchParams(queryString);
              params.forEach((value, key) => {
                queryParams[key] = value;
              });
            }
          
            // Extract the ID
            const urlId = fragment.split('?')[0];
          
            if (urlId === id) {
              const activeTab = queryParams.activeTab ? parseInt(queryParams.activeTab) : 0;
              const tabComponent = document.getElementById(id);
              if (tabComponent) {
                tabComponent.scrollIntoView();
              }
              return {activeTab, urlParam: true};
            }
          
            return {activeTab: 0, urlParam: false};
          }

    //event listeners for each tab
    document.querySelectorAll('.tab-panel--container').forEach(tabpanel => {
        const tabListDesktop = Array.from(tabpanel.querySelectorAll('.tab-panel--desktop'));
        const tabListMobile = Array.from(tabpanel.querySelectorAll('.tab-panel--mobile'));

        // init state on desktop
        if(window.innerWidth >= 992){
            //if tabpanel has url param
            if(tabpanel.id && getActiveTabFromUrl(tabpanel.id).activeTab > 0){
                let activeTabIndex = getActiveTabFromUrl(tabpanel.id).activeTab;
                tabpanel.querySelectorAll('.tab-control')[activeTabIndex].classList.add('active');
                tabpanel.querySelectorAll('.tab-control')[activeTabIndex].setAttribute('aria-expanded', 'true');
                
                if(tabListDesktop[activeTabIndex]){
                    tabListDesktop[activeTabIndex].classList.add('active');
                }
                
                //init state on desktop, no url param
            } else {
                tabpanel.querySelectorAll('.tab-control')[0].classList.add('active');
                tabpanel.querySelectorAll('.tab-control')[0].setAttribute('aria-expanded', 'true');
                if(tabListDesktop[0]){
                    tabListDesktop[0].classList.add('active');
                }
            }
        //init state on mobile    
        } else {
            if(tabpanel.id && getActiveTabFromUrl(tabpanel.id).urlParam){
                let activeTabIndex = getActiveTabFromUrl(tabpanel.id).activeTab;
                tabpanel.querySelectorAll('.tab-control')[activeTabIndex].classList.add('active');
                tabpanel.querySelectorAll('.tab-control')[activeTabIndex].setAttribute('aria-expanded', 'true');
                tabListMobile[activeTabIndex].classList.add('active');
            } 
        }

        //add event listeners to tabs
        tabpanel.querySelectorAll('.tab-control').forEach((tab, index) => {
            tab.addEventListener('click', () => {
                //if the window is tablet or down
                if(window.innerWidth < 992){
                    tab.classList.toggle('active');
                    tabListMobile[index].classList.toggle('active');
                    tab.setAttribute('aria-expanded', tab.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
                    
                    //if the window is desktop
                } else {
                    tabListDesktop.forEach(list => {
                        if(list.classList.contains('active')){
                            list.classList.toggle('active')
                        }
                    })
                    tabpanel.querySelectorAll('.tab-control').forEach(tab => {tab.setAttribute('aria-expanded','false')})
                    tab.setAttribute('aria-expanded','true');
                    tabpanel.querySelector('.tab-control.active').classList.remove('active');
                    tab.classList.add('active');
                    tabListDesktop[index].classList.add('active');
                }
            })
        }) 
    })}

    //window resize event listener
    let windowSize = window.innerWidth;
    window.addEventListener('resize', throttle(() => {

        //if desktop, revert to 1st tab being open
        //check if innerwidth is desktop, and if previous width was tablet/mobile
        if(window.innerWidth >= 992 && windowSize < 992){
            //remove active class from all mobile tabs
            Array.from(document.querySelectorAll('.tab-panel--mobile'))
                .filter(div => div.classList.contains('active'))
                .forEach(div => div.classList.remove('active'));
            Array.from(document.querySelectorAll('.tab-control'))
                .filter(div => div.classList.contains('active'))
                .forEach(div => div.classList.remove('active'));
            document.querySelectorAll('.tab-panel--container').forEach(tabpanel => {
                tabpanel.querySelectorAll('.tab-control').forEach(tab => tab.setAttribute('aria-expanded', 'false'));
                tabpanel.querySelectorAll('.tab-control')[0].classList.add('active');
                tabpanel.querySelectorAll('.tab-panel--desktop')[0].classList.add('active');
                tabpanel.querySelectorAll('.tab-control')[0].setAttribute('aria-expanded', 'true');
            })
            
            windowSize = window.innerWidth;
        //if changing from desktop to tablet/mobile, keep tab open
        } else if (window.innerWidth <= 992 && windowSize > 992){
            Array.from(document.querySelectorAll('.tab-panel--desktop'))
                .filter(div => div.classList.contains('active'))
                .forEach(div => div.classList.remove('active'));
            Array.from(document.querySelectorAll('.tab-control'))
                .filter(div => div.classList.contains('active'))
                .forEach(div => div.parentElement.querySelector('.tab-panel--mobile').classList.add('active'))
            windowSize = window.innerWidth
        } else {
            windowSize = window.innerWidth
        }
    }), 200)

}

export default tabpanelReworked;