export function checkHolidays(date){
/**
* Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar 
* based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php
* 
*/
    const year = date.getFullYear()
    let leapYear = false;

  if ((year % 4 == 0) && ((year % 100 != 0)) || (year % 400 == 0)) {
    leapYear = true;
  };
    
  //uderegner påskedag, month og day
	var f = Math.floor,
		// Golden Number - 1
		G = year % 19,
		C = f(year / 100),
		// related to Epact
		H = (C - f(C / 4) - f((8 * C + 13)/25) + 19 * G + 15) % 30,
		// number of days from 21 March to the Paschal full moon
		I = H - f(H/28) * (1 - f(29/(H + 1)) * f((21-G)/11)),
		// weekday for the Paschal full moon
		J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
		// number of days from 21 March to the Sunday on or before the Paschal full moon
		L = I - J,
		month = 3 + f((L + 40)/44),
		day = L + 28 - 31 * f(month / 4);

  //variabel til at finde ud af hvilken nummer dag på året, påskedag er
  let easterSunday;

  //finder ud af om påskesøndag er i marts eller april, sætter dagsnummer derefter
  easterSunday = month == 3 ? 59 : 90;

  //lægger dage til.
  easterSunday = easterSunday + day;
  if(leapYear){ easterSunday += 1 }

  //dynamiske helligdage der lægger fast ift. easterSunday
  let skaerTorsdag = easterSunday - 3;
  let langFredag = easterSunday - 2;
  let andenPaaskedag = easterSunday + 1;
  let kristiHimmelfart = easterSunday + 39;
  let andenPinsedag = easterSunday + 50;
  
  //statiske helligdage
  let nytaarsdag = 1;
  let juledag = leapYear ? 360 : 359;
  let andenJuledag = leapYear ? 361 : 360;
  
  //andre (hellig)dage, ikke implementeret, men kan tilføjes til arrayet, hvis de også skal være lukkedage
  let storeBededag = easterSunday + 26; // removed  2023
  let foersteMaj = leapYear ? 122 : 121;
  let grundlovsdag = leapYear ? 157 : 156;
  let juleaftensdag = leapYear ? 359 : 358;
  let nytaarsaftensdag = leapYear ? 366 : 365;

  const holidays = [skaerTorsdag, langFredag, andenPaaskedag, kristiHimmelfart, andenPinsedag, nytaarsdag, juledag, andenJuledag];

  //get the current date number
  let currentDateNumber = 0;

  let currentMonth = date.getMonth(); 
  let currentDay = date.getDate(); 
  let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  // If it's a leap year, changes 28 to 29
  if (leapYear) { monthLength[1] = 29; }

  for ( let i=0; i < currentMonth; i++ ) { 
    currentDateNumber = currentDateNumber + monthLength[i];
  }
  currentDateNumber = currentDateNumber + currentDay; // Done!

  let yearLength = 365;
  if(leapYear){ yearLength++ }
  let mandag = currentDateNumber - date.getDay() + 1

  let tirsdag = mandag + 1 <= yearLength ? mandag + 1 : 1;
  let onsdag = tirsdag + 1 <= yearLength ? tirsdag + 1 : 1;
  let torsdag = onsdag + 1 <= yearLength ? onsdag + 1 : 1;
  let fredag = torsdag + 1 <= yearLength ? torsdag + 1 : 1;
  let lørdag = fredag + 1 <= yearLength ? fredag + 1 : 1;
  let søndag = lørdag + 1 <= yearLength ? lørdag + 1 : 1;

  let næsteUgeMandag = søndag + 1 <= yearLength ? søndag + 1 : 1;
  let næsteUgeTirsdag = næsteUgeMandag + 1 <= yearLength ? næsteUgeMandag + 1 : 1;

  let days = [
    holidays.includes(mandag),
    holidays.includes(tirsdag),
    holidays.includes(onsdag),
    holidays.includes(torsdag),
    holidays.includes(fredag),
    holidays.includes(lørdag),
    holidays.includes(søndag)
  ]
  let holidaysNextWeek = [
    false,
    !holidays.includes(næsteUgeMandag),
    !holidays.includes(næsteUgeTirsdag),
    true
  ]

  //return
  return {
    isHoliday: holidays.includes(currentDateNumber),
    holidaysInCurrentWeek: days,
    holidaysNextWeek
  }
}

export default checkHolidays