/**
 * Hero Multi Box
 *
 */

export function heroMultiBox() {
  if (jQuery('.hero__multi-boxes').length < 1) {
    return;
  }

  jQuery(function() {
    jQuery(window).on('load', function() {
      if (jQuery(window).width() > 768) {
        masonryGrid();
      }
    });

    // if (jQuery(window).width() > 768) {
    //   masonryGrid();
    // }

    jQuery(window).on("resize", function () {
      if (jQuery(window).width() > 768) {
        masonryGrid();
      }
    });
  });

  function masonryGrid() {
    jQuery('.hero__multi-boxes__grid.hero__multi-boxes__grid--vertical.hero__multi-boxes__grid__two').masonry({
      itemSelector: '.hero__multi-boxes__box',
      columnWidth: '.grid-column-one',
      percentPosition: true,
      originTop: false
    });

    jQuery('.hero__multi-boxes__grid.hero__multi-boxes__grid--horizontal.hero__multi-boxes__grid__two').masonry({
      itemSelector: '.hero__multi-boxes__box',
      columnWidth: '.grid-column-two',
      percentPosition: true,
      originTop: false
    });

    jQuery('.hero__multi-boxes__grid.hero__multi-boxes__grid--vertical').masonry({
      itemSelector: '.hero__multi-boxes__box',
      columnWidth: '.grid-column-two',
      percentPosition: true,
      originTop: false
    });

    jQuery('.hero__multi-boxes__grid.hero__multi-boxes__grid--horizontal').masonry({
      itemSelector: '.hero__multi-boxes__box',
      columnWidth: '.grid-column-two',
      horizontalOrder: true,
      percentPosition: true,
    });
  }
}

export default heroMultiBox;
