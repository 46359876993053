export function kultunautMostPopular() {
	if (jQuery(".kultur-popularevents").length > 0) {
		getPopularEvents();
	}
}

function getPopularEvents() {
	var feedList = jQuery(".kultur-popularevents");
	feedList.each(function (index) {
		var current = jQuery(this).data("eventlist");
		var requestData = {
			"feed": jQuery(this).data("feed"),
		}
		$.get('/umbraco/surface/Kultunaut/GetTopEvent', requestData, function (data) {
			var newData = data.trim();
			jQuery(".kultur-popularevents[data-eventlist='" + current + "']").html(newData).hide().fadeIn(600);
		}).fail(function () {
			console.log("Error in loaded events")
		});
	});

}


export default kultunautMostPopular;
