export function cludoSearch() {
  var accordion = jQuery('.cludo__filter-list');

    // Mobile only
    if (window.matchMedia("(max-width: 991px)").matches) {

      if (accordion.length > 0) {
        accordion = (function () {
          // var $accordion = jQuery('.js-info-box__links__icon');
          var $accordion_item = jQuery('.cludo__filter-list');
          var $accordion_header = jQuery('.cludo__filter-list__title__toggle');
          var $accordion_body = jQuery('.cludo__filter-list__content');

          // default settings
          var settings = {
            // animation speed
            speed: 400,
            // close all other accordion items if true
            oneOpen: false
          };

          return {
            // pass configurable object literal
            init: function ($settings) {
              $accordion_header.off('click');
              $accordion_header.on('click', function (e) {
                e.preventDefault();
                accordion.toggle(jQuery(this));
              });

              $.extend(settings, $settings);

              // reveal the active accordion bodies
              jQuery('.cludo__filter-list.active').find('> .cludo__filter-list__content').show();
            },

            toggle: function ($this) {
              // show/hide the clicked accordion item
              $this.attr("aria-expanded", "false");
              $this.closest($accordion_item).toggleClass('active');
              $this.closest($accordion_item).find($accordion_body).stop().slideToggle(settings.speed);

              if ($this.closest($accordion_item).hasClass('active')) {
                $this.attr("aria-expanded", "true");
              }
            },

            destroy: function () {
              $accordion_header.off('click');

              // styling
              $accordion_item.find('> .cludo__filter-list__content').hide();
              $accordion_item.removeClass('active');
              $accordion_item.find('> .cludo__filter-list__content').css("height", "auto");
              $accordion_item.find('> .cludo__filter-list__content').show();
            }
          }
        })();

        var viewportWidth = jQuery(window).width();

        jQuery(window).on("load resize", function () {

          if (this.matchMedia("(max-width: 991px)").matches) {
            accordion.init({
              speed: 300,
              oneOpen: true
            });
          }

          if (this.matchMedia("(min-width: 991px)").matches) {
            accordion.destroy();
          }

        });

      }
    }

}

export default cludoSearch;
