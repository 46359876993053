/**
 * Pagination
 * https://codepen.io/robertcooper_rc/pen/XeabLa
 */

export function pagination() {

  window.onload = function(){
    var paginationPage = parseInt(jQuery('.pagination').attr('actpage'), 10);
    jQuery(document).on("click",".pagination__item button",function() {
      var go = jQuery(this).attr('data-button');

      if (go === '+1') {
        paginationPage++;
      } else if (go === '-1') {
        paginationPage--;
      } else {
        paginationPage = parseInt(go, 10);
      }
      jQuery('.pagination').attr('actpage', paginationPage);
      jQuery('.arrow-next').attr("data-page", paginationPage+1);
      jQuery('.arrow-prev').attr("data-page", paginationPage-1);

    });
  };
}

export default pagination;
