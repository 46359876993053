export default function onShowMoreCard() {
	const cardComment = document.querySelectorAll('.js-card__comment');

	const disableFocusable = (element) => {
		element.setAttribute('tabindex', '-1');
	};

	const enableFocusable = (element) => {
		element.removeAttribute('tabindex');
	};

	const handleFocusables = (focusables, content) => {
		let isCollapsed = content.classList.contains('closed');

		focusables.forEach((focusable) => {
			isCollapsed ? disableFocusable(focusable) : enableFocusable(focusable);
		});
	};

	cardComment.forEach((card) => {
		const elementButton = card.querySelector('.btn-toggle');
		const content = card.querySelector('.js-card__comment__content');
		const focusables = Array.from(card.getElementsByTagName('a'));

		handleFocusables(focusables, content);

		elementButton.addEventListener('click', () => {
			handleFocusables(focusables, content);
		});

	});
}
