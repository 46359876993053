/*
** Block media download elements
** Optional data-attribute
*/

export function blockMediaDownload() {

  if (jQuery('body[data-block-media-download]').length) {
    jQuery('img').each(function() {
      // Check if the image is NOT inside a div with the class 'image-gallery'
      if (!jQuery(this).closest('.image-gallery').length) {
        
        // Add the class to block media download
        jQuery(this).addClass("pointer-events-none");
      }
    });
  } else {
    return;
  }

}

export default blockMediaDownload;
