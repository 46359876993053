export default function onShowMore() {
	const factBox = document.querySelectorAll('.fact-box--collapsible');

	const disableFocusable = (element) => {
		element.setAttribute('tabindex', '-1');
	};

	const enableFocusable = (element) => {
		element.removeAttribute('tabindex');
	};

	const handleFocusables = (focusables, richText) => {
		let isCollapsed = richText.classList.contains('closed');

		focusables.forEach((focusable) => {
			isCollapsed ? disableFocusable(focusable) : enableFocusable(focusable);
		});
	};

	factBox.forEach((box) => {
		const elementButton = box.querySelector('.read-more-button');
		const richText = box.querySelector('.js_rich-text');
		const focusables = Array.from(box.getElementsByTagName('a'));

		handleFocusables(focusables, richText);

		elementButton.addEventListener('click', () => {
			handleFocusables(focusables, richText);
		});

	});
}
