/**
 * Side box counter animation
 */

export function counterAnimation() {
  if (jQuery('.side-boxes__counter').length < 0) {
    return;
  }

  //each, prop, animate, math, text

// .animate( properties [, duration ] [, easing ] [, callback ] )
// "swing" - moves slower at the beginning/end, but faster in the middle
// "linear" - moves in a constant speed
// step : A function to be called after each step of the animation.  step takes: now and fx.
// jQuery(selector).prop(name,value)

  // jQuery('.count').each(function() {

  //   jQuery(this).prop('counter', -1).animate({

  //     counter: jQuery(this).text()

  //   }, {

  //     duration: 2500,

  //     easing: 'swing',

  //     step: function(now) {

  //       jQuery(this).text(Math.ceil(now));
  //     }
  //   });
  // });


}

export default counterAnimation;
