/**
 * Data Tabel
 *
 */

export function dataTabel() {
	// console.log(jQuery('.table').length)
	if (jQuery('.table').length > 0) {
		var lang_da_mod = {
			"sProcessing": "Henter...",
			"sLengthMenu": "Vis _MENU_ linjer",
			"sZeroRecords": "Ingen linjer matcher s&oslash;gningen",
			"sInfo": "Viser _START_ til _END_ af _TOTAL_ linjer",
			"sInfoEmpty": "Viser 0 til 0 af 0 linjer",
			"sInfoFiltered": "(filtreret fra _MAX_ linjer)",
			"sInfoPostFix": "",
			"sSearch": "S&oslash;g:",
			"sUrl": "",
			"oPaginate": {
				"sFirst": "F&oslash;rste",
				"sPrevious": "Forrige",
				"sNext": "N&aelig;ste",
				"sLast": "Sidste"
			}
		};
		jQuery('.table').each(function () {
			var table = jQuery(this);
			var id = table.attr("id");
			var search = table.data('search');
			var searchfield = table.data('searchfield');

			if (jQuery(this).hasClass('table--collapse')) {
				var entries = table.data('entries');
				var all = table.data('all');
				var lenghMenu = table.data('lengthmenu');
				var pagination = table.data('pagination');
				var info = table.data('info');

				//Api lang moved

				lang_da_mod.lenghMenu = lenghMenu;
				lang_da_mod.pagination = pagination;
				lang_da_mod.info = info;
				lang_da_mod.searchfield = searchfield;
				lang_da_mod.sLengthMenu = '<select>' + '<option value="-1">' + all + '</option>' + '<option value="10" selected>10</option>' + '<option value="20">20</option>' + '<option value="30">30</option>' + '</select>';
				lang_da_mod.searchPlaceholder = "";
				lang_da_mod.sSearch = search;
				lang_da_mod.info = entries;
				lang_da_mod.oPaginate.sNext = "";
				lang_da_mod.oPaginate.sPrevious = "";
				addTable(id, lang_da_mod);
				// $.ajax({
				// 	dataType: "json",
				// 	url: "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Danish.json",
				// 	success: function (data) {
				// 	}
				// });
			} else {
				// console.log("NOt collapse")
				entries = table.data('entries');
				lenghMenu = table.data('lengthmenu');
				pagination = table.data('pagination');
				info = table.data('info');

				lang_da_mod.lenghMenu = lenghMenu;
				lang_da_mod.pagination = pagination;
				lang_da_mod.searchfield = searchfield;
				lang_da_mod.searchPlaceholder = "";
				lang_da_mod.sSearch = search;
				lang_da_mod.info = entries;
				lang_da_mod.oPaginate.sNext = "";
				lang_da_mod.oPaginate.sPrevious = "";
				addTableScrollable(id, lang_da_mod);
				// $.ajax({
				// 	dataType: "json",
				// 	url: "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Danish.json",
				// 	success: function (data) {
				// 	}
				// });
			}
		});
	}

}

function addTable(id, lang_da_mod) {
	jQuery('#' + id).DataTable({
		ordering : true,
		retrieve: true,
		"info": lang_da_mod.info,
		"bFilter": lang_da_mod.searchfield,
		"bLengthChange": lang_da_mod.lenghMenu,
		"bPaginate": lang_da_mod.pagination,
		responsive: {
			details: {
				type: 'column'
			}
		},
		columnDefs: [{
			className: 'control',
			orderable: false,
			targets: 0
		}],
		"language": lang_da_mod
	});

	setTimeout(function () {
		jQuery('.table').css('opacity', 1)
		jQuery('.dataTables_wrapper').css('opacity', 1)

		jQuery('.dataTables_wrapper input').prop('required', true);
		jQuery('.dataTables_wrapper input').parent('label').attr('aria-label', 'Søg i tabel');
	}, 100);
}

function addTableScrollable(id, lang_da_mod) {
	// console.log(id)

	jQuery(window).on("load resize", function () { // Mobile
		if (this.matchMedia("(max-width: 767px)").matches) {
			var tableScrollableTh = jQuery('#' + id).find('th');

			if (tableScrollableTh.length <= 3) {
				jQuery('.table-icon-scroll').css("display", "none");
			}

			jQuery(window).scroll(function () {
				var top_of_element = jQuery('#' + id).offset().top;
				var bottom_of_element = jQuery('#' + id).offset().top + jQuery('#' + id).outerHeight();
				var bottom_of_screen = jQuery(window).scrollTop() + jQuery(window).innerHeight();
				var top_of_screen = jQuery(window).scrollTop();

				if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) { // the element is visible
					jQuery('.table-icon-scroll').css("opacity", 1);
				} else { // the element is not visible
					jQuery('.table-icon-scroll').css("opacity", 0);
				}

				if ((bottom_of_screen > bottom_of_element) && (top_of_screen < bottom_of_element)) { // End of element
					jQuery('.table-icon-scroll').css("opacity", 0);
				}
			});
		}
	});

	jQuery('#' + id).DataTable({
		ordering : true,
		retrieve: true,
		"info": lang_da_mod.info,
		"bFilter": lang_da_mod.searchfield,
		"bLengthChange": lang_da_mod.lenghMenu,
		"bPaginate": lang_da_mod.pagination,
		columnDefs: [{
			className: 'control',
			orderable: false,
			targets: 0
		}],
		fixedColumns: true,
		"language": lang_da_mod,
		// scrollY: "450px", // Not accessible with scroll both ways
		scrollX: true,
		scrollCollapse: true,
	});

	setTimeout(function () {
		jQuery('.table').css('opacity', 1)
		jQuery('.dataTables_wrapper').css('opacity', 1)

		jQuery('.dataTables_wrapper input').prop('required', true);
		jQuery('.dataTables_wrapper input').parent('label').attr('aria-label', 'Søg i tabel');
	}, 100);
}

export default dataTabel;
