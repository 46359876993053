/**
 * Chat
 */

export function chat() {
	if(document.querySelector('.chat')) {
		const maxHeightContainer = jQuery(".chat-wall--max-height-container");
		const windowInnerHeight = window.innerHeight;
		const header = jQuery(".header__megamenu-extended");
		const headerHeight = header.outerHeight();

		//Toggle comments
		//Comment button
		const commentBtnElements = document.querySelectorAll('.js-button-comment');
		commentBtnElements.forEach((btn) => {
			const commentsContainer = btn.parentNode.parentNode.querySelector('.chat__feed__item__comments');

			btn.addEventListener('click', () => {
				if(commentsContainer.classList.contains('collapsed')){
					commentsContainer.classList.remove('collapsed')
					commentsContainer.querySelectorAll('input')[Array.length - 1].focus();
				} else {
					commentsContainer.classList.add('collapsed')
				}

			})
		})

		// Get the content editable <p> element
		const editorContainer = document.querySelector('#editor-container');
		const chatPostContainer = document.querySelector('.chat__post__form--container')

		// Add a focus event listener to the <p> element
		editorContainer.addEventListener('focusin', function() {
			chatPostContainer.classList.remove('collapsed')
			setTimeout(function(){
				chatPostContainer.style.overflow = 'visible'
			}, 200)

		});

		//add close functionality to button
		const closeBtnEelement = document.querySelector('.chat__post__form--close-btn')
		// console.log(closeBtnEelement)
		if(closeBtnEelement != undefined) {
			closeBtnEelement.addEventListener('click', function(){
				chatPostContainer.classList.add('collapsed')
				chatPostContainer.style.overflow = 'hidden'
			})
		}

		jQuery(document).ready(function () {
			jQuery('button.chat-dropdown-toggle').on('click', function () {
				jQuery(this).toggleClass('show');
				jQuery(this).siblings(' .chat-dropdown-menu').toggleClass('show');
			});

			// Close filter selection on click outside toggle selection
			jQuery(document).mouseup(function(e) {
				var container = jQuery(".chat-dropdown");

				// if the target of the click isn't the container
				if (!container.is(e.target) && container.has(e.target).length === 0) {
					if(jQuery('.chat-dropdown .chat-dropdown-toggle').hasClass('show')) {
						jQuery('.chat-dropdown .chat-dropdown-toggle').removeClass('show');
						jQuery('.chat-dropdown .chat-dropdown-menu').removeClass('show');
						jQuery('.chat-dropdown .chat-dropdown-toggle').attr("aria-expanded", "false");
					}
				}
			});

			// Chat-wall measure height for 'max-height-container'
			if (window.matchMedia('(min-width: 1200px)').matches) {
				setMaxHeightContainer();
			}

			function setMaxHeightContainer() {
				if(maxHeightContainer.length > 0) {
					jQuery(window).on( 'scroll', function(){
						if (jQuery(window).scrollTop() < 800){
							var scrollTop     = jQuery(window).scrollTop(),
									elementOffset = maxHeightContainer.offset().top,
									distance      = (elementOffset - scrollTop);

							maxHeightContainer.css({"maxHeight": windowInnerHeight - distance - 30+"px"});
						}
					});
				}
			}
		});
	}
}

export default chat;
