export function articleAnchorMenu() {

	// If headlines have an .article-anchor, create anchor menu
	if (jQuery('.rich-text').length > 0) {
		var links = jQuery('.rich-text a[id]');

		if(links.length > 0) {
			addSection(links);
			jQuery('.article-anchor-menu').show();
		} else {
			jQuery('.article-anchor-menu').remove();
		}
	} else {
		jQuery('.article-anchor-menu').remove();
	}

	// add id to link en headline
	function addSection(links) {
		links.each(function () {
			var name = jQuery(this).parent().text();
			var anchor = cleanText(jQuery(this).attr("id").toLocaleLowerCase());
			jQuery(this).attr('id', anchor);
			jQuery(".article-anchor-menu ol").append("<li><a href='#" + anchor + "'>"+ name +"</a></li>");
		});
	}

	function cleanText(text) {
		var cleanText = text.replace('æ', 'ae').replace('ø', 'oe').replace('å', 'aa').replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-').toLowerCase();
		return cleanText;
	}
    function addSpaceToText(text) {
		var fixedText = text.toLowerCase().replace(/_/g, " ").replace('aa', 'å').replace('oe', 'ø').replace('ae', 'æ');
		return fixedText;
	}
}

export default articleAnchorMenu;
