// var moment = require('moment'); // require
export function kultunaut() {
  if (jQuery(".kultur-detailedevents").length > 0) {
    getdetailedevents();
  }

  if (jQuery(".kultur-events").length > 0) {
    getevents();
  }
}

function getevents() {
  var feedList = jQuery(".kultur-events");

  feedList.each(function (index) {
    var current = jQuery(this).data("eventlist");
    var requestData = {
      "feed": jQuery(this).data("feed"),
      "amount": jQuery(this).data("amount")
    }

  $.get('/umbraco/surface/Kultunaut/GetEvents', requestData, function (data) {
    var newData = data.trim();
    jQuery(".kultur-events[data-eventlist='" + current + "']").prepend(newData).hide().fadeIn(600);

    }).fail(function () {
      console.log("Error in loaded comments")
    });
  });
}

function getdetailedevents() {
  var feedList = jQuery(".kultur-detailedevents");
  feedList.each(function (index) {
    var current = jQuery(this).data("eventlist");
    var requestData = {
      "feed": jQuery(this).data("feed"),
      "amount": jQuery(this).data("amount")
    }
    
    $.get('/umbraco/surface/Kultunaut/GetDetailedEvents', requestData, function (data) {
      var newData = data.trim();
      jQuery(".kultur-detailedevents[data-eventlist='" + current + "']").prepend(newData).hide().fadeIn(600);

    }).fail(function () {
      console.log("Error in loaded comments")
    });
  });
}

export default kultunaut;
