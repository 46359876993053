export default () => ({
	mobileSearch: false,
	mobileSideMenu: false,
	focusTrap: true,
	desktopSearch: false,
	mobileInputFocused: false,
	mobileInputValue: "",
	isMobile: true,

	init() {
		let isMenuTooWide = false;
		let doubleLogoMenuElement = false;
		let leftAlignedMenuElement = false;
		
		let doubleLogoMaxWidth;
		let headerLeftAlignedMaxWidth;

		const updateWindowProperties = () => {
			//if load is on tablet or mobile, keep as is for all subsequent resizes
			if(doubleLogoMaxWidth === 0 ){
				this.mobileSideMenu = false;
				this.focusTrap = true;
				this.isMobile = true;
			} else if (headerLeftAlignedMaxWidth === 0) {
				this.mobileSideMenu = false;
				this.focusTrap = true;
				this.isMobile = true;

			} else if (isMenuTooWide && doubleLogoMenuElement) {
				this.mobileSideMenu = false;
				this.focusTrap = false;
				this.isMobile = true;
			} else if (isMenuTooWide && leftAlignedMenuElement){
				this.mobileSearch = false;
				this.mobileSideMenu = false;
				this.focusTrap = true;
				this.isMobile = true;
			} else if (!window.matchMedia("(max-width: 991px)").matches) {
				this.mobileSearch = false;
				this.mobileSideMenu = true;
				this.focusTrap = false;
				this.isMobile = false;
			} else {
				this.mobileSearch = false;
				this.mobileSideMenu = false;
				this.focusTrap = true;
				this.isMobile = true;
			}
		};

		// Initial update
		updateWindowProperties();

		// Add window resize event listener
		window.addEventListener("resize", () => checkMenuWidth());

		// function to check if menus are too long to fit on screen (on desktop)
		const checkMenuWidth = () => {
			doubleLogoMenuElement = document.querySelector('#headerDoubleLogo') ? document.querySelector('#headerDoubleLogo') : false;
			leftAlignedMenuElement = document.querySelector('.header--left-aligned-container') ? document.querySelector('.header--left-aligned-container') : false;

			//check double logo menu
			if(doubleLogoMenuElement && !window.matchMedia("(max-width: 991px)").matches){

				const width = doubleLogoMenuElement.querySelector('.header--double-logo-sidemenu').clientWidth - 40 - 40;
				const sidemenu = document.querySelector('.header--double-logo-sidemenu');
				const logoWidth = sidemenu.querySelector('.nav-brand').clientWidth;

				const searchbarWidth = 400;		
				const combinedWidth = doubleLogoMaxWidth + searchbarWidth + logoWidth;

				//if sidemenu width is 0 (page was loaded on tablet for example), keep tablet menu
				if(doubleLogoMaxWidth == 0){
					isMenuTooWide = true;
				
				//collapse if width too large to fit
			 	} else if(combinedWidth > width){
					isMenuTooWide = true;
					updateWindowProperties();			
					document.querySelector('.mobile-actions-burger-btn').addEventListener('click', () => {
						document.querySelector('html').style.overflow = 'visible'
					})
				//expand if there is space for the menu
				} else {
					isMenuTooWide = false;
					if(doubleLogoMenuElement.classList.contains('header__collapsed')) {doubleLogoMenuElement.classList.remove('header__collapsed')}
					updateWindowProperties();
				}
			}

			//check left aligned menu
			if(leftAlignedMenuElement && !window.matchMedia("(max-width: 991px)").matches){

				//const sidemenuWidth = leftAlignedMenuElement.querySelector('.header--left-aligned-sidemenu-list').scrollWidth;
				const width = leftAlignedMenuElement.clientWidth - 40 - 40;
				const searchBar = leftAlignedMenuElement.querySelector('.header--left-aligned-sidemenu').querySelector('.desktop-searchbar') ? true : false;
				const searhbarWidth = searchBar ? 300 : 0;
				const combinedWidth = headerLeftAlignedMaxWidth + searhbarWidth;
				// console.log('width:' + width)
				// console.log('max width: ' + headerLeftAlignedMaxWidth)

				//is width is 0 (loaded on tablet for example), keep collapsed menu
				if(headerLeftAlignedMaxWidth === 0 ){
					isMenuTooWide = true;
					// if menu items are too wide, collapse
				} else if(combinedWidth > width){
					isMenuTooWide = true;
					updateWindowProperties();
					
					document.querySelector('.mobile-actions-burger-btn').addEventListener('click', () => {
						document.querySelector('html').style.overflow = 'visible'
					})

				//if menu is not too wide, keep as is
				} else {
					isMenuTooWide = false;
					if(leftAlignedMenuElement.classList.contains('header__collapsed')) {leftAlignedMenuElement.classList.remove('header__collapsed')}
					updateWindowProperties();
				}
			} else {
				updateWindowProperties();
			}
		}

		// Check if menu is too large to contain all items
		document.addEventListener('DOMContentLoaded', () => {
			doubleLogoMaxWidth = document.querySelector('.header--double-logo-sidemenu-list') ? document.querySelector('.header--double-logo-sidemenu-list').scrollWidth : false;
			headerLeftAlignedMaxWidth = document.querySelector('.header--left-aligned-sidemenu-list') ? document.querySelector('.header--left-aligned-sidemenu-list').clientWidth : false;
			checkMenuWidth();
		})
	},
});
