/*
** Tooltips
*/
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export function tooltips() {

  if (jQuery('.intext-tooltip').length == 0) {
      return;
  }
  tippy('.intext-tooltip', {
    theme: 'custom',
    arrow: true,
    maxWidth: 385,
    placement: 'auto',
    delay: 5,
    hideOnClick: false,
    onShow(instance) {
      var content = instance.reference.attributes["data-tippy-content"].value;
      instance.setContent(content);
    }
  });

}

export default tooltips;
