export function dropdownMdDown() {

	if (jQuery(".dropdown--md-down").length < 0) {
		return;
  }

  jQuery(window).on("load resize", function () {
    var $dropdownToggle = jQuery(".dropdown--md-down .dropdown--md-down-toggle");
    var $dropdownMenu = jQuery('.dropdown--md-down .dropdown--md-down-menu');

    // Tablet down
    if (this.matchMedia("(max-width: 991.98px)").matches) {
      $dropdownToggle.removeAttr("disabled");
      $dropdownToggle.attr("aria-expanded", "false");

      // Tablet dropdowns - show
      jQuery(document).off('click', '.dropdown--md-down .dropdown--md-down-toggle');
      jQuery(document).on('click', '.dropdown--md-down .dropdown--md-down-toggle', function () {
        jQuery(this).attr("aria-expanded", "true");
        jQuery(this).toggleClass('show');
        jQuery(this).parents('.dropdown--md-down').toggleClass('show');
        jQuery(this).siblings('.dropdown--md-down-menu').slideToggle('show');
        // jQuery('body').toggleClass('dropdown--md-down--open');
      });

      // Tablet dropdowns - close
      jQuery(document).off('click', '.dropdown--md-down .show');
      jQuery(document).on('click', '.dropdown--md-down .show', function () {
        jQuery(this).attr("aria-expanded", "false");
        jQuery(this).removeClass('show');
        jQuery(this).children('.dropdown--md-down-toggle').removeClass('show');
        jQuery(this).children('.dropdown--md-down-menu').removeClass('show');
        // jQuery('body').removeClass('dropdown--md-down--open');
      });

      // Tablet down - disable dropdowns on click outside
      // document.addEventListener(
      //   "click",
      //   (event) => {
      //     let target = event.target;
      //     // diable button, ready to open input on click.
      //     if (!target.closest(".dropdown--md-down")) {
      //       $dropdownToggle.removeClass('show');
      //       $dropdownMenu.removeClass('show');
      //       $dropdownToggle.removeAttr("aria-expanded");
      //       $dropdownMenu.css("display", "");
      //       // jQuery('body').removeClass('dropdown--md-down--open');
      //     }
      //   },
      //   false
      // );
    } else {
      $dropdownToggle.attr("disabled");
      jQuery(document).off('click', '.dropdown--md-down .dropdown--md-down-toggle');
      jQuery(document).off('click', '.dropdown--md-down .show');
    }

    if (this.matchMedia("(min-width: 991.98px)").matches) {
      // Tablet up - disable dropdowns
      $dropdownToggle.attr("disabled", "disabled");
      $dropdownToggle.removeAttr("aria-expanded");
      $dropdownMenu.css("display", "");
      $dropdownToggle.removeClass('show');

      if (jQuery('.dropdown--md-down').hasClass('.show')) {
        $dropdownToggle.removeClass('show');
        $dropdownMenu.removeClass('show');
        // jQuery('body').removeClass('dropdown--md-down--open');
      }
    }
  });

}

export default dropdownMdDown;
