import hoverIntent from "../../../../node_modules/superfish/dist/js/hoverintent";
import superfish from "../../../../node_modules/superfish/dist/js/superfish.min";
import Headroom from "headroom.js";

// Navbar
export function headerMegamenuExtended() {
  if (jQuery(".header__megamenu-extended").length < 0) {
    return;
  }

  if (jQuery("header").hasClass("header__megamenu-extended")) {
    // Variables
    const $body = jQuery("body");
    const $dropdown = jQuery(".header__megamenu-extended .nav-item.dropdown");
    const $navLink = jQuery(".header__megamenu-extended .nav-link");
    const $dropdownToggle = jQuery(".header__megamenu-extended .dropdown-toggle");
    const $dropdownMenu = jQuery(".header__megamenu-extended .dropdown-menu");
    const $searchbar = jQuery(".header__megamenu-extended__search-bar");
    const $searchbarToggle = jQuery(".navbar__search-toggle");
    const showClass = "sfHover";
    const header = jQuery(".header__megamenu-extended");
    const $dropdownToggleTab = jQuery(".header__megamenu-extended .dropdown-toggle__tab");
    const $submenuDropdownToggle = jQuery(".header__megamenu-extended .dropdown-submenu__button");
    var $textOpen = $searchbarToggle.attr("data-textOpen");
    var $textClose = $searchbarToggle.attr("data-textClose");
    // const headerHeight = jQuery(header).outerHeight();
    let isMenuOpen = false;

    // Body scroll-lock variables
    const bodyScrollLock = require("body-scroll-lock");
    const disableBodyScroll = bodyScrollLock.disableBodyScroll;
    const enableBodyScroll = bodyScrollLock.enableBodyScroll;

		// sets the top padding to the height of the header.
		const setPaddingMain = () => {
			let headerHeight = header.outerHeight();
			jQuery("main").css("padding-top", headerHeight);

      document.documentElement.style.setProperty('--header-height-lg', headerHeight + 'px');
      // sets position of menu to fixed once height has been calculated. The if statement is only for the local front end page with multiple menus, so it only sets the position on the top menu.
      if(jQuery(".header__megamenu-extended")[1]){
        jQuery(".header__megamenu-extended")[0].style.position = 'fixed'
      } else {
        jQuery(".header__megamenu-extended")[0].style.position = 'fixed'
      }
		}

    // onscroll function
    let headerElement = document.querySelector(".header__megamenu-extended")
    let oldScrollHeight = window.scrollY;
    let height = headerElement.offsetHeight;

    const onScroll = () => {
      // if (jQuery(window).width() >= 991){
        //reference variables
        var progress = jQuery('progress:not(.progressbar__value)')[0];
        let newScrollHeight = window.scrollY;

        //if scrolling up, menu appears
        if(newScrollHeight < oldScrollHeight && newScrollHeight > height) {
          if (progress && jQuery(window).width() >= 991) {
            progress.style.top = header.outerHeight() + 'px';
          }
          headerElement.style.position = 'sticky';
          headerElement.style.top = '0px';

          //if scrolling down and scrollheight is higher than menu height, menu disappears
        } else if ((newScrollHeight > oldScrollHeight && newScrollHeight > height) && !isMenuOpen){
          if (progress  && jQuery(window).width() >= 991) {
            progress.style.top = '0px';
          }
          headerElement.style.top = '-' + height + 'px'
          setTimeout(() => headerElement.style.position = 'relative', 200)


        //if scroll height is less than height of menu, fix it to the top
        } else if (newScrollHeight < height){
          headerElement.style.top = '0px';
        }

        //set reference variable to current scroll height
        oldScrollHeight = newScrollHeight;
      // }
    }

    //function that runs on load. Also runs on resize
    const onLoad = (window) => {
      jQuery("main").css("padding-top", 0);

      //only sets main padding on mobile
      // if(jQuery(window).width() < 991 ) {
      //   setPaddingMain()
      // } else {
      //   jQuery("main").css("padding-top", 0);
      // }

      // Desktop menu
      if (jQuery(window).width() >= 991) {
        header[0].style.position = 'relative';
        // Hover aim - SuperFish
        // jQuery(".header__megamenu-extended ul.navbar-nav__primary").superfish({
        //   popUpSelector: "ul, .dropdown-menu__megamenu",
        //   // delay: 500, // mouseout delay, ms
        //   speed: 200, // ms, cref jquery animate()
        //   //disableHI: true, // disabled because it only works properly if the megamenu is a ul
        // });

        // Store a reference to the timeout
        let hoverIntentTimeout;

        // Function to be executed when hover intent is triggered
        function handleHoverIntent(element) {
          listElements.forEach(element => {
            element.classList.remove('visible')
          })
          element.classList.add("visible");
        }

        // Function to be executed when hover intent is not triggered
        function handleHoverOut(element) {
          hoverIntentTimeout = setTimeout(() => {
            listElements.forEach(element => {
              element.classList.remove('visible')
            })
            //element.classList.remove("visible");
          }, 300); // Delay removal in milliseconds
        }

        // Add event listeners to all list elements
        const listElements = document.querySelectorAll(".nav-item");
        listElements.forEach(element => {
          element.addEventListener("mouseenter", () => {
            // Clear previous timeout (if any)
            clearTimeout(hoverIntentTimeout);

            // Set a new timeout to trigger hover intent after a delay
            hoverIntentTimeout = setTimeout(() => {
              handleHoverIntent(element);
            }, 300); // Delay in milliseconds
          });

          element.addEventListener("mouseleave", () => {
            // Clear the timeout when leaving the element
            clearTimeout(hoverIntentTimeout);

            // Handle hover out
            handleHoverOut(element);
          });
        });

        jQuery(".header__megamenu-extended .dropdown-submenu .dropdown-menu").show();
        jQuery("nav").removeClass("show-mobile-menu");

        // Remove focus on all menu items
        $navLink.on('focus', function () {
          $navLink.closest($dropdown).removeClass(showClass);
          $navLink.find($dropdownToggle).attr("aria-expanded", "false");
          $navLink.closest($dropdown).find($dropdownMenu).removeClass(showClass);
        });

        // Focus on menu items - for web accessibility
        $dropdownToggle.on('focus', function () {
          const $this = jQuery(this);
          $this.closest($dropdown).addClass(showClass);
          //$this.closest($dropdown).removeClass(showClass);
          //$this.attr("aria-expanded", "true");
          $this.closest($dropdown).find($dropdownMenu).addClass(showClass);
          $this.closest($dropdown).find($dropdownMenu).removeClass(showClass);
          $this.closest($dropdown).find($dropdownMenu).css({"display": "none"});
          $dropdownToggleTab.attr("aria-expanded", "false")
        });

        $dropdownToggleTab.on('click', function () {
          const $this = jQuery(this);
          $this.closest($dropdown).addClass(showClass);
          $this.attr("aria-expanded", "true");
          $this.closest($dropdown).removeClass(showClass);
          $this.closest($dropdown).find($dropdownMenu).addClass(showClass);
          $this.closest($dropdown).find($dropdownMenu).removeClass(showClass);
          $this.closest($dropdown).find($dropdownMenu).css({"display": "block"});
        })
      }

      // Mobile menu (Tablet down)
      if (jQuery(window).width() < 991) {
        header[0].style.position = 'relative';
        // window.onscroll = false;
        // header[0].style.position = 'fixed';
        // header[0].style.top = '0px';

        // Remove text from Tab button when on tablet/mobile
        $dropdownToggleTab.text('');
        //add aria-expand label to submenu buttons
        $submenuDropdownToggle.attr('aria-expanded', 'false')

        jQuery(".navbar-toggler").on("click", function (e) {
          var $sidemenu = jQuery(".header__megamenu-extended .side-menu");
          var $sidemenuToggle = jQuery(".header__megamenu-extended .side-menu .side-menu__toggle-mobile");
          $searchbarToggle.attr("aria-expanded", "false");

          if ($body.hasClass("burger-open") && jQuery('.navbar-collapse').hasClass("show")) {
            closeMobileMenu();
          } else {
            openMobileMenu();
          }

          // If sidemenu is open
          if ($sidemenu.hasClass("show")) {
            $sidemenu.removeClass("show");
            $sidemenuToggle.removeClass("show");
            jQuery("body").removeClass("position-fixed");

            if ($sidemenuToggle.hasClass("show")) {
              $sidemenuToggle.attr("aria-expanded", "true");
            } else {
              $sidemenuToggle.attr("aria-expanded", "false");
            }
          }
        });

        function openMobileMenu() {
          jQuery("body").addClass("burger-open");
          jQuery("body").removeClass("burger-closed");
          jQuery("nav").addClass("show-mobile-menu");
          jQuery(".navbar-toggler-icon").addClass("open");
          jQuery(".header__megamenu-extended__search-bar").slideUp();
        }

        function closeMobileMenu() {
          jQuery("body").addClass("burger-closed");
          jQuery("body").removeClass("burger-open");
          jQuery("nav").removeClass("show-mobile-menu");
          jQuery(".navbar-toggler-icon").removeClass("open");

          // Return dropdown menu to original state
          jQuery(".header__megamenu-extended .navbar-collapse .dropdown-menu__megamenu").removeClass("show");
          jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").removeClass("show");
          jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").children(".nav-link").removeClass("show");
          jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").children(".dropdown-menu").slideUp("show");
          jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").find(".dropdown-submenu__link").removeClass("show");
          jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").find(".dropdown-submenu__button").removeClass("show");
          jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").find(".dropdown-submenu__ul").removeClass("show");
        }

        // Mobile 2nd level dropdowns
        jQuery(document).off("click", ".header__megamenu-extended .navbar-collapse .dropdown-submenu .nav-link");
        jQuery(document).on("click", ".header__megamenu-extended .navbar-collapse .dropdown-submenu .nav-link",
          function () {
            if(jQuery(this).attr('aria-expanded') == 'false'){
              jQuery(this).attr('aria-expanded', 'true')
            } else {
              jQuery(this).attr('aria-expanded', 'false')
            }
            jQuery(this).toggleClass("show");
            jQuery(this).siblings().toggleClass('show')
            jQuery(this).parent().next('.dropdown-submenu__ul').toggleClass('show');
            //jQuery(this).parents(".header__megamenu-extended .navbar-collapse .dropdown-submenu").toggleClass("show");
            //jQuery(this).siblings(".header__megamenu-extended .navbar-collapse .dropdown-menu").slideToggle("show");
          }
        );

        // Return to first level menu
        jQuery(document).on("click", ".nav-back-link", function () {
          jQuery(this).attr('aria-expanded', 'false');
          setTimeout(function () {
            setTimeout(function () {
              closeAccordions();
            }, 200);
            goBack();
          }, 200);

          // Go back function
          function goBack() {
            jQuery(".nav-back-link").closest(".dropdown-menu__megamenu").removeClass("show");
          }

          // Close all accordions when returing to 1st level menu - to keep height of menu
          function closeAccordions() {
            jQuery(".header__megamenu-extended .dropdown-submenu").removeClass("show");
            jQuery(".header__megamenu-extended .dropdown-submenu").children(".nav-link").removeClass("show");
            jQuery(".header__megamenu-extended .dropdown-submenu").children(".dropdown-menu").slideUp("show");
            $dropdownToggleTab.attr('aria-expanded', 'false')
          }
        });

        // Disable bootstrap dropdown functionality
        jQuery(".dropdown-toggle__mobile").on('click', function () {
          jQuery(this).attr("aria-expanded", "true");
          jQuery(this).siblings(".nav-link.dropdown-toggle").removeAttr("data-toggle", "dropdown");
          jQuery(this).parent().siblings(".dropdown-menu__megamenu").addClass("show");
          jQuery(".navbar__megamenu-extended.show-mobile-menu").scrollTop(0);
        });

        // Click on megamenu search bar in mobile
        $searchbarToggle.on("click", function () {
          var $sidemenu = jQuery(".header__megamenu-extended .side-menu");
          var $sidemenuToggle = jQuery(
            ".header__megamenu-extended .side-menu .side-menu__toggle-mobile"
          );

          // If sidemenu is open
          if ($sidemenu.hasClass("show")) {
            $sidemenu.removeClass("show");
            $sidemenuToggle.removeClass("show");
            jQuery("body").removeClass("position-fixed");

            if ($sidemenuToggle.hasClass("show")) {
              $sidemenuToggle.attr("aria-expanded", "true");
            } else {
              $sidemenuToggle.attr("aria-expanded", "false");
            }
          }
        });
      }
    }

    function debounce(func, wait) {
      let timeout;
      return function() {
        const context = this;
        const args = arguments;
        const later = function() {
          timeout = null;
          func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }

    jQuery(window).on("resize", function () {
      onLoad(window);
    });

    jQuery(window).on("load", function () {
      onLoad(window);
    });

    // Debounced scroll handler
    const onScrollDebounced = debounce(onScroll, 50); // Adjust the debounce delay as needed

    jQuery(window).on("scroll", function () {
      onScrollDebounced(); // Call the debounced function
      //onScroll();
    })

    // // event handlers for navbar togglers (toggle menu open)
    // jQuery(".navbar-toggler").on("click", function (e) {
    //   var $sidemenu = jQuery(".header__megamenu-extended .side-menu");
    //   var $sidemenuToggle = jQuery(".header__megamenu-extended .side-menu .side-menu__toggle-mobile");

    //   if ($body.hasClass("burger-open") && jQuery('.navbar-collapse').hasClass("show")) {
    //     closeMobileMenu();
    //   } else {
    //     openMobileMenu();
    //   }

    //   // If sidemenu is open
    //   if ($sidemenu.hasClass("show")) {
    //     $sidemenu.removeClass("show");
    //     $sidemenuToggle.removeClass("show");
    //     jQuery("body").removeClass("position-fixed");

    //     if ($sidemenuToggle.hasClass("show")) {
    //       $sidemenuToggle.attr("aria-expanded", "true");
    //     } else {
    //       $sidemenuToggle.attr("aria-expanded", "false");
    //     }
    //   }
    // });

    function openMobileMenu() {
      jQuery("body").addClass("burger-open");
      jQuery("body").removeClass("burger-closed");
      jQuery("nav").addClass("show-mobile-menu");
      jQuery(".navbar-toggler-icon").addClass("open");
      jQuery(".header__megamenu-extended__search-bar").slideUp();
    }

    function closeMobileMenu() {
      jQuery("body").addClass("burger-closed");
      jQuery("body").removeClass("burger-open");
      jQuery("nav").removeClass("show-mobile-menu");
      jQuery(".navbar-toggler-icon").removeClass("open");

      // Return dropdown menu to original state
      jQuery(".header__megamenu-extended .navbar-collapse .dropdown-menu__megamenu").removeClass("show");
      jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").removeClass("show");
      jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").children(".nav-link").removeClass("show");
      jQuery(".header__megamenu-extended .navbar-collapse .dropdown-submenu").children(".dropdown-menu").slideUp("show");
    }

    // Displays megamenu search bar
    $searchbarToggle.on("click", function () {
      isMenuOpen = !isMenuOpen;

      jQuery(".header__megamenu-extended__search-bar").slideToggle();
      if(jQuery(this).attr("aria-expanded") == "true"){
        jQuery(this).attr("aria-expanded", "false");
        jQuery(this).attr("aria-label", $textOpen);
      } else  {
        jQuery(this).attr("aria-expanded", "true");
        jQuery(this).attr("aria-label", $textClose);
      }
      jQuery(".header__megamenu-extended__search-bar__form .form-group input").focus();
    });


    var $elementLosingFocus = jQuery( "#search-submit" );

    $elementLosingFocus.on("focusout", function (event) {
      var $elementYouAreTesting;

      $elementYouAreTesting = jQuery(".header__megamenu-extended__search-bar__form input");

    if ($elementYouAreTesting[0] === event.relatedTarget) {
          // you clicked the element you were testing!
      } else {
          // you clicked something else
          jQuery(".header__megamenu-extended__search-bar").slideToggle();
          jQuery(".navbar__search-toggle").attr("aria-expanded", "false");
          $searchbarToggle.attr("aria-label", $textOpen);
      }
    });

    // Hide megamenu serchbar when megamenu open
    jQuery(document).on("click", ".nav-link.dropdown-toggle", function () {
      isMenuOpen = false;
      jQuery(".header__megamenu-extended__search-bar").slideUp();
    });
  }
}

export default headerMegamenuExtended;
