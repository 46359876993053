/**
 * Swiper carousel
 * https://swiperjs.com/
 */

// import Swiper and modules styles
import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard, A11y, EffectFade } from 'swiper/modules';
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/keyboard'
import 'swiper/css/a11y';

export function swiper() {
  if (jQuery(".swiper").length === 0) {
    return;
  }

  function initSwipers(defaults = {}, selector = ".swiper") {
    let swipers = document.querySelectorAll(selector);
  
    swipers.forEach((swiperContainer, index) => {
      // Create a unique class name for the swiper container
      let swiperClass = 'swiper-' + index;
  
      // Add the unique class to the swiper container if not already added
      if (!swiperContainer.classList.contains(swiperClass)) {
        swiperContainer.classList.add(swiperClass);
  
        // Get options
        let optionsData = swiperContainer.dataset.swiper
          ? JSON.parse(swiperContainer.dataset.swiper)
          : {};

        // Get dottext from data attribute
        let dottext = swiperContainer.getAttribute('data-dottext') || 'Gå til slide #';

        // Check if the swiper should be active only on mobile
        let shouldEnableSwiper = optionsData.mobileOnly && window.innerWidth <= 768;

        // Combine defaults and custom options
        let options = {
          ...defaults,
          ...optionsData,
          navigation: {
            nextEl: swiperContainer.querySelector('.swiper-button-next'),
            prevEl: swiperContainer.querySelector('.swiper-button-prev'),
          }, // Set navigation options specific to this swiper
          pagination: {
            ...defaults.pagination,
            el: swiperContainer.querySelector('.swiper-pagination'),
            renderBullet: function (index, className) {
              let ariaLabel = dottext.replace('#', index + 1);
              return `<li class="${className}">
                        <button aria-label="${ariaLabel}"></button>
                      </li>`;
            },
          },
        };
  
        // Initialize Swiper only if it should be active on the current device
        if (!optionsData.mobileOnly || shouldEnableSwiper) {

          // Initialize Swiper
          let swiperInstance = new Swiper(swiperContainer, options);
    
          // Check if navigation elements exist within the swiper container
          let navigation = swiperContainer.querySelector('.swiper-button-prev, .swiper-button-next');
          if (navigation) {
    
            navigation.addEventListener('click', function (event) {
              event.preventDefault();
    
              if (event.target.classList.contains('swiper-button-prev')) {
                swiperInstance.slidePrev();
              } else if (event.target.classList.contains('swiper-button-next')) {
                swiperInstance.slideNext();
              }
            });
          }
        }
      }
    });
  }  

  // Basic options for all sliders
  let defaults = {
    modules: [Navigation, Pagination, Keyboard, A11y, EffectFade],
    slidesPerView: 1,
    spaceBetween: 30,
    autoHeight: false,
    effect: "fade",
    loop: true,
    lazy: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    keyboard: {
      enabled: true,
    },
    A11y: {
      enabled: true
    },
  };
  
  // Call the init function
  initSwipers(defaults);

}

export default swiper;
