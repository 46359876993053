/*
 ** Form elements
 ** JS help for custom form elements
 */

import button from "bootstrap/js/src/button";

export function formElements() {
	// if (jQuery('.filter-menu').length < 0) {
	// return;
	// }

	// Web accessibility
	jQuery('.select').on('focus', function () {
		if (jQuery('.select').attr('aria-expanded') === 'true') {
			jQuery('.select').attr('aria-expanded') === 'false';
			jQuery('.select-checkboxes.dropdown .dropdown-toggle')
				.parent('.dropdown')
				.removeClass('show');
			jQuery('.select-checkboxes.dropdown .dropdown-toggle')
				.siblings('.dropdown-menu')
				.removeClass('show');
		}
	});

	jQuery('.select-checkboxes.dropdown .dropdown-toggle').on('focus', function () {
		if (jQuery(this).attr('aria-expanded') === 'true') {
			jQuery(this).parent('.dropdown').toggleClass('show');
			jQuery(this).siblings('.dropdown-menu').toggleClass('show');
		}
	});

	jQuery('.select-checkboxes.dropdown .dropdown-menu').on('click', function (event) {
		// The event won't be propagated up to the document NODE and
		// therefore delegated events won't be fired
		event.stopPropagation();
	});

	jQuery('.textarea.with-counterJS textarea').on('input', function () {
		var maxlength = jQuery(this).attr('maxlength');
		var currentLength = jQuery(this).val().length;
		let minLength = jQuery(this).attr('minlength');



		// disable button and show warning for min length
if (minLength !== undefined) {
	if (currentLength >= minLength) {
		jQuery(this).parent('.textarea.with-counterJS').find(jQuery('.invalid-feedback')).css('visibility', 'hidden');
		jQuery(this).parents('.feedback-banner__message').find(jQuery('.send-response')).removeAttr('disabled')
	} else {
		jQuery(this).parent('.textarea.with-counterJS').find(jQuery('.invalid-feedback')).css('visibility', 'visible');
		jQuery(this).parents('.feedback-banner__message').find(jQuery('.send-response')).attr('disabled', 'true')
	}
}


		if (currentLength >= maxlength) {
			return
		}

		var textareaContainer = jQuery(this).parent('.textarea.with-counterJS');
		var currentLenghtContainer = textareaContainer.find(
			'.textarea-counter__currentlength p'
		);
		currentLenghtContainer.text(currentLength);
	});
}

export default formElements;
