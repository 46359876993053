// Video
export function video() {

  if (jQuery(".video").length === 0) {
    return;
  }

  jQuery( ".video" ).each(function() {
    jQuery(this).find(".video__overlay__button").click(function() {
      jQuery(this).parent('.video__overlay').addClass("hide");
      let video = jQuery(this).closest(".video").find('video').get(0);

      if (!video) {
        console.error("Video element not found");
        return;
      }

      video.style.visibility = "visible";

      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
		})
  });
}

export default video;
