export default () => ({
  mobileSearch: false,
  mobileSideMenu: false,
  focusTrap: true,
  desktopSearch: false,
  mobileInputFocused: false,
  mobileInputValue: "",

  init() {
    const updateWindowProperties = () => {
      if (!window.matchMedia("(max-width: 991px)").matches) { 
        this.mobileSideMenu = true;
        this.focusTrap = false;
      } else {
        this.mobileSideMenu = false;
        this.focusTrap = true;
      }
    };

    // Initial update
    updateWindowProperties();

    // Add window resize event listener
    window.addEventListener("resize", updateWindowProperties);
  },

  toggleMobileMenu() {
    this.mobileSideMenu = !this.mobileSideMenu;
    this.mobileSearch = false;
  }
});