
export function doubleLogoMenu() {

  // if megamenu
  if(document.querySelector('#headerDoubleLogo') && document.querySelector('.list-item-left-aligned-toggle')){

    const toggleElements = [...document.querySelectorAll('.list-item-left-aligned-toggle')];

    toggleElements.forEach(item => {

      //toggle function for desktop
      if(window.innerWidth > 768){

        item.addEventListener('click', event => {

          if(true){
            event.preventDefault()
            let isVisible = item.nextElementSibling.classList.contains('visible');
            
            let activeMenus = item.closest('ul').querySelectorAll('.visible')
            if(activeMenus[0] != undefined){
              activeMenus.forEach(item => {
                item.classList.remove('visible')
                item.previousElementSibling.setAttribute('aria-expanded', 'false')
                item.previousElementSibling.classList.remove('active')
              })
            }
            if(isVisible){
              item.nextElementSibling.classList.remove('visible');
              item.setAttribute('aria-expanded', 'false')
              item.classList.remove('active')
            } else if (!isVisible) {
              item.nextElementSibling.classList.add('visible');
              item.setAttribute('aria-expanded', true)          
              item.classList.add('active')
            }
          } else {
            return
          }
            

        })

      //toggle function for mobile
      } else if (window.innerWidth < 769) {

        item.addEventListener('click', () => {
          item.nextElementSibling.classList.toggle('visible');

          if(item.getAttribute('aria-expanded') == 'false'){
            item.setAttribute('aria-expanded', true)
            item.querySelector('.list-item-megamenu-toggle-icon').style.transform = 'translateY(0%) rotate(315deg)'
          } else {
            item.setAttribute('aria-expanded', 'false')
            item.querySelector('.list-item-megamenu-toggle-icon').style.transform = 'translateY(-50%) rotate(135deg)'
          }
        })
      }
    })

    //hover intent for desktop
    if(window.innerWidth > 768){
      // Store a reference to the timeout
      let hoverIntentTimeout;

      // Function to be executed when hover intent is triggered
      function handleHoverIntent(element) {
        listElements.forEach(element => {
          element.classList.remove('visible')
        })
        element.classList.add("visible");
      }

      // Function to be executed when hover intent is not triggered
      function handleHoverOut(element) {
        hoverIntentTimeout = setTimeout(() => {
          listElements.forEach(element => {
            element.classList.remove('visible')
          })
          //element.classList.remove("visible");
        }, 300); // Delay removal in milliseconds
      }

      // Add event listeners to all list elements
      const listElements = document.querySelectorAll(".sidemenu-list-item");
      listElements.forEach(element => {
        element.addEventListener("mouseenter", () => {
          // Clear previous timeout (if any)
          clearTimeout(hoverIntentTimeout);

          // Set a new timeout to trigger hover intent after a delay
          hoverIntentTimeout = setTimeout(() => {
            handleHoverIntent(element);
          }, 300); // Delay in milliseconds
        });

        element.addEventListener("mouseleave", () => {
          // Clear the timeout when leaving the element
          clearTimeout(hoverIntentTimeout);

          // Handle hover out
          handleHoverOut(element);
        });
      });
    }
  }
  if(document.querySelector('#headerDoubleLogo')){
    //variables that need to be declared in global scope
    const menuElement = document.querySelector('#headerDoubleLogo')
    let oldScrollHeight;
    let height;
  
    //set variables whether collapsed menu or not (should refire on resize)
    function setScrollValues(){
      //reference variable
      oldScrollHeight = window.scrollY;
      height = menuElement.offsetHeight;
      if(window.innerWidth > 768){
        document.querySelector('main').style.paddingTop = 0;
      }
    }
    setScrollValues();
    window.addEventListener('resize', () => setScrollValues())
  
    //window.onscroll function. Should be one function for both desktop and mobile
  
    window.addEventListener('scroll', () => {

      //if desktop menu
      if(window.innerWidth > 768){

  
        let newScrollHeight = window.scrollY;
        //if scrolling up, menu appears
        if(newScrollHeight < oldScrollHeight && newScrollHeight > height){

          menuElement.parentElement.style.position = 'sticky';
          menuElement.parentElement.style.top = '0px';
  
        //if scrolling down and scrollheight is higher than menu height, menu disappears
        } else if (newScrollHeight > oldScrollHeight && newScrollHeight > height){
          menuElement.parentElement.style.top = '-' + height + 'px'
          setTimeout(() => menuElement.parentElement.style.position = 'relative', 200)
  
        //if scroll height is less than height of menu, fix it to the top
        } else if (newScrollHeight < height){
          menuElement.parentElement.style.top = '0px';
        }
  
        //set reference variable to current scroll height
        oldScrollHeight = newScrollHeight;
  
  
  
      //if mobile (collapsed) menu
      } else if(window.innerWidth < 769){
        //const menuElement = document.querySelector('#headerDoubleLogo')
        let newScrollHeight = window.scrollY;
        const headerDoubleLogoTopBar = document.querySelector('.header--double-logo-top-bar');
    
        document.querySelector('main').style.paddingTop = menuElement.offsetHeight + 'px'
        menuElement.parentElement.style.position = 'fixed';
        menuElement.parentElement.style.top = '0'
        // ---- Scroll hide menu function ---- //
    
        if (headerDoubleLogoTopBar) { 
          //reference variable
          let height = document.querySelector('.header--double-logo-top-bar').offsetHeight;

          //if scrolling up but not at top
          if(newScrollHeight < oldScrollHeight && newScrollHeight > height){
            menuElement.parentElement.style.position = 'fixed';
            menuElement.parentElement.style.top = '-' + (height + 1) + 'px';
  
          //if scrolling down and scrollheight is higher than menu height, menu disappears
          } else if (newScrollHeight > oldScrollHeight && newScrollHeight > height){
            // console.log('scrolling down')
            menuElement.parentElement.style.top = '-' + (height + 1) + 'px'
            menuElement.parentElement.style.position = 'fixed'
  
          //if scroll height is less than height of menu, fix it to the top
          } else if (newScrollHeight < height){
            menuElement.parentElement.style.top = '0px';
          }
  
          //set reference variable to current scroll height
          oldScrollHeight = newScrollHeight;
          
        }
        
      }
    }
  )
  }

  // ---- Scroll hide menu function ---- //
/*   if(document.querySelector('#headerDoubleLogo') && window.innerWidth > 769){

    console.log(document.querySelector('.collapsed-menu'))


    window.onscroll = function(){

    }

  } else if (document.querySelector('#headerDoubleLogo') && window.innerWidth < 769){


  } */
}

export default doubleLogoMenu;