export function footers() {

	if (jQuery(".footer").length < 0) {
		return;
  }

  jQuery(window).on("load resize", function () {
    var $dropdownToggle = jQuery(".footer__content .dropdown-toggle");
    var $dropdownMenu = jQuery('.footer__content .dropdown-menu');

    // Mobile menu (Tablet down)
    if (this.matchMedia("(max-width: 767px)").matches) {
      $dropdownToggle.removeAttr("disabled");
      $dropdownToggle.removeAttr("role");
      $dropdownToggle.removeClass("show");
      $dropdownToggle.removeAttr("aria-level");
      $dropdownToggle.attr("aria-expanded", "false");

      // Mobile 2nd level dropdowns
      jQuery(document).off('click', '.footer__content .dropdown-toggle');
      jQuery(document).on('click', '.footer__content .dropdown-toggle', function () {
        jQuery(this).attr("aria-expanded", "true");
        jQuery(this).toggleClass('show');
        jQuery(this).parents('.footer__content').toggleClass('show');
        jQuery(this).siblings('.dropdown-menu').slideToggle('show');
      });

      // Mobile: if submenu menu item is open and it is clicked --> close it
      jQuery(document).off('click', '.footer__content .show');
      jQuery(document).on('click', '.footer__content .show', function () {
        jQuery(this).attr("aria-expanded", "false");
        jQuery(this).removeClass('show');
        jQuery(this).children('.dropdown-toggle').removeClass('show');
        jQuery(this).children('.dropdown-menu').removeClass('show');
      });
    } else {
        $dropdownToggle.attr("disabled");

        // Mobile 2nd level dropdowns
        jQuery(document).off('click', '.footer__content .dropdown-toggle');

        // Mobile: if submenu menu item is open and it is clicked --> close it
        jQuery(document).off('click', '.footer__content .show');
      }

    if (this.matchMedia("(min-width: 768px)").matches) {
      $dropdownToggle.attr("disabled", "disabled");
      $dropdownToggle.attr("aria-level", "2");
      $dropdownToggle.attr("role", "heading");
      $dropdownToggle.removeAttr("aria-expanded");
      $dropdownMenu.css("display", "");

      if (jQuery('.footer__content').hasClass('.show')) {
        $dropdownToggle.removeClass('show');
        $dropdownMenu.removeClass('show');
      }
    }
  });

}

export default footers;
