/**
 * Three column carousel - carousel on tablet down
 * https://owlcarousel2.github.io/
 */

import 'imagesloaded';
import 'masonry-layout/masonry.js';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

export function imageGallery() {

  if (jQuery(".image-gallery").length == 0) {
		return;
  }

  jQuery('.image-gallery').imagesLoaded(function(){
    jQuery('.image-gallery').masonry({
        itemSelector: '.image-gallery__item',
        horizontalOrder: true,
        percentPosition: true
    });
  });

  var imageGallery = jQuery(".image-gallery");
  imageGallery.each(function(index, element) {

    new Viewer(element, {
      backdrop: true,
      button:true,
      inheritedAttributes: ['data-caption'],
      title: function(image) {
        var imgCaption = image.getAttribute('data-caption')
        return imgCaption;
      },
      url: function(image){
        return image.getAttribute('data-src-viewer');
      },
      toolbar: {
        zoomIn: 0,
        zoomOut: 0,
        oneToOne: 0,
        reset: 0,
        prev: 1,
        play: {
          show: 0,
        },
        next: 1,
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
      },
      transition: false
    })
  });
}

export default imageGallery;
