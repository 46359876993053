// Grayscale Script

export function greyScaleInit() {
	const grayscaleBtnElements = document.querySelectorAll(".jsGrayscaleToggle");
	if (grayscaleBtnElements.length === 0) {
		return;
	}

	const grayscaleClass = "grayscale";
	const grayscaleOnClass = "grayscale--on";
	const grayscaleFilterClass = "js-grayscale--filter";
	const grayscaleStorageKey = "colorThemeGrayscale"; // Unique storage key for grayscale

	const getSelectedGrayscaleTheme = () =>
		localStorage.getItem(grayscaleStorageKey);

	const setGrayscaleTheme = (theme) =>
		localStorage.setItem(grayscaleStorageKey, theme);

	// Target specific elements for the JS functionality
	const targetElements = [document.querySelector('header'), document.querySelector('main'), document.querySelector('footer')];

	const updateGrayscaleState = (isGrayscaleActive) => {
		// Apply or remove the filter class from header, main, and footer
		targetElements.forEach(element => {
			if (element) {
				if (isGrayscaleActive) {
					element.classList.add(grayscaleFilterClass);
				} else {
					element.classList.remove(grayscaleFilterClass);
				}
			}
		});

		// Update all buttons to reflect the current state
		grayscaleBtnElements.forEach((item) => {
			if (isGrayscaleActive) {
				item.classList.add(grayscaleOnClass);
				item.classList.add("active");
			} else {
				item.classList.remove(grayscaleOnClass);
				item.classList.remove("active");
			}
		});

		// Update the theme in local storage
		setGrayscaleTheme(isGrayscaleActive ? grayscaleClass : "regular");
	};

	const grayscaleInitState = () => {
		const isGrayscaleActive = getSelectedGrayscaleTheme() === grayscaleClass;
		document.body.classList.toggle(grayscaleOnClass, isGrayscaleActive);
		updateGrayscaleState(isGrayscaleActive);
	};

	grayscaleInitState();

	// Ensure only one event listener is added per button
	const handleClick = (event) => {
		// console.log('Button clicked:', event.currentTarget);
		// Determine if grayscale is currently active
		const isGrayscaleActive = !document.body.classList.contains(grayscaleOnClass);

		// Toggle the grayscale state
		document.body.classList.toggle(grayscaleOnClass, isGrayscaleActive);
		updateGrayscaleState(isGrayscaleActive);
	};

	// Remove any existing event listeners to prevent duplication
	grayscaleBtnElements.forEach((button) => {
		button.removeEventListener("click", handleClick); // Ensure previous listeners are removed
		button.addEventListener("click", handleClick);
	});
}

export default greyScaleInit;