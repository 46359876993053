export function accordionMobile() {
  var accordion = jQuery('.accordion--mobile');

  if (accordion.length > 0) {
    var accordionInstance = (function() {
      var settings = {
        speed: 400,
        oneOpen: false
      };

      return {
        init: function($settings) {
          jQuery(document).on("click", ".accordion--mobile__toggle", function(e) {
            e.preventDefault();
            accordionInstance.toggle(jQuery(this));
          });

          $.extend(settings, $settings);

          jQuery('.accordion--mobile.active').find('> .accordion--mobile__body').show();
        },
        toggle: function($this) {
          $this.attr("aria-expanded", function(_, attr) {
            return attr === "true" ? "false" : "true";
          });

          if (settings.oneOpen) {
            var activeAccordion = $this.closest('.accordion--mobile.active');
            if (activeAccordion.length && !$this.closest('.accordion--mobile').hasClass('active')) {
              activeAccordion.removeClass('active').find('.accordion--mobile__body').slideUp(settings.speed);
            }
          }

          $this.closest('.accordion--mobile').toggleClass('active').find('.accordion--mobile__body').stop().slideToggle(settings.speed);
        },
        destroy: function() {
          // Remove event listeners
          // jQuery(document).off("click", ".accordion--mobile__toggle");
          jQuery('.accordion--mobile').find('.accordion--mobile__toggle').removeAttr('aria-expanded');
          // Reset accordion state
          jQuery('.accordion--mobile').removeClass('active').find('.accordion--mobile__body').removeAttr('style');
        }
      };
    })();

    // Initialize accordion
    accordionInstance.init({ speed: 300, oneOpen: true });

    // Function to handle accordion destruction and reinitialization on window resize
    function handleAccordionState() {
      if (window.matchMedia("(min-width: 768px)").matches) {
        // Disable toggle button
        jQuery('.accordion--mobile__toggle').prop('disabled', true);
        // Check if accordion is initialized
        if (accordionInstance && !accordion.hasClass('active')) {
          accordionInstance.destroy();
        }
      } else {
        // Enable toggle button
        jQuery('.accordion--mobile__toggle').prop('disabled', false);
        // Check if accordion is not initialized
        if (!accordionInstance) {
          // Initialize accordion
          accordionInstance.init({ speed: 300, oneOpen: true });
        } else {
          // Remove inline style attribute from accordion body
          // jQuery('.accordion--mobile__body').removeAttr('style');
          // Set aria-expanded attribute based on accordion state
          jQuery('.accordion--mobile__toggle').attr('aria-expanded', function() {
            return jQuery(this).closest('.accordion--mobile').hasClass('active') ? 'true' : 'false';
          });
        }
      }
    }

    // Handle accordion state on window resize
    jQuery(window).on('load resize', handleAccordionState);
  }
}

export default accordionMobile;