// import hoverIntent from '../../../../node_modules/superfish/dist/js/hoverintent'
// import superfish from '../../../../node_modules/superfish/dist/js/superfish.min'
import Headroom from 'headroom.js';

// Navbar
export function headerIntranet() {
	var navigationHandler = {
		navbarDesktop: function () {
			return jQuery('.navbar-collapse .navbar-nav')[0]
		},
		navbarDesktopItems: function () {
			return jQuery('.navbar-nav__desktop .nav-item:not(.nav-back-link)')
		},
		navbarMobileMenu: function () {
			return jQuery('.navbar-collapse .navbar-nav')
		},
		searchBar: function () {
			return jQuery('.nav-item__search-input')
		},
		navbarProfile: function () {
			return jQuery('.profile-info')[0]
		},
		ensureMobileMenu: function () {
			var navbarDesktopItems = this.navbarDesktopItems();
			var navbarMobileMenu = this.navbarMobileMenu();
			var searchBar = this.searchBar();
			navbarMobileMenu.append(navbarDesktopItems);
			navbarMobileMenu.append(searchBar);

			//remove desktop UL for accessability
			jQuery('.navbar-nav__desktop').remove();
		},
		ensureDesktopMenu: function () {
			//add desktop UL for accessability
			if (jQuery('.navbar-collapse--desktop').find('.navbar-nav__desktop').length === 0) {
				jQuery('.navbar-collapse--desktop').append('<ul class="navbar-nav__desktop"></ul>');
			}

			if (!this.navbarDesktop().children[this.navbarDesktop().children.length - 1].classList.contains('nav-item-profile')) {
				this.navbarDesktop().append(this.navbarProfile());
			}
		},
		checkItemsInBurger: function () {
			// eslint-disable-next-line no-inner-declarations
			if (jQuery('.navbar-nav__desktop li.nav-item').length >= 1) {
				jQuery('.header__intranet').addClass('has-burger-desktop')
			} else {
				jQuery('.header__intranet').removeClass('has-burger-desktop')
			}

		},
		checkForSpace: function (space, index, navItems, navItemBurgerList, burgerNavItems, burgerNavLastItem, navbarBrandWidth) {
			// eslint-disable-next-line no-inner-declarations
			if (space <= 0) {
				navItems = jQuery('.navbar-nav li.nav-item');
				navItemBurgerList.append(navItems[navItems.length - 1]);
				space = jQuery('.header__intranet').outerWidth(true) - jQuery('.navbar-nav').outerWidth(true) - jQuery('.navbar-brand').outerWidth(true);

				this.checkItemsInBurger();

			} else if (space > burgerNavLastItem.data("nav-item-width")) {
				burgerNavItems = jQuery('.navbar-nav__desktop li.nav-item');
				jQuery('.navbar-nav li.nav-item:last').after(burgerNavItems[burgerNavItems.length - 1]);
				space = jQuery('.header__intranet').outerWidth(true) - jQuery('.navbar-nav').outerWidth(true) - navbarBrandWidth;

				this.checkItemsInBurger();
			} else {
				return;
			}

			if (index > 5) {
				return;
			}

			index++
			this.checkForSpace(space, index, navItems, navItemBurgerList, burgerNavItems, burgerNavLastItem, navbarBrandWidth);
		}
	}

	if (jQuery(".header__intranet").length < 0) {
		return;
	}

	if (jQuery("header").hasClass("header__intranet")) {
		// Variables
		const $dropdown = jQuery(".header__intranet .nav-item.dropdown");
		const $navLink = jQuery(".header__intranet .nav-link");
		const $dropdownToggle = jQuery(".header__intranet .dropdown-toggle");
		const $dropdownMenu = jQuery(".header__intranet .dropdown-menu");
		const showClass = "sfHover";
		const header = jQuery(".header__intranet");

    		// sets the top padding to the height of the header.
		const setPaddingMain = () => {
			let headerHeight = header.outerHeight();
			jQuery("main").css("padding-top", headerHeight);

      document.documentElement.style.setProperty('--header-height-lg', headerHeight + 'px');
		}

    setPaddingMain()

    jQuery(window).on("resize", function () {
      var $window = jQuery(this);

      if ($window.width() >= 991) {
        setPaddingMain()
      }
    });

		// Profile menu
		jQuery(document).off('click', '.dropdown-toggle__profile');
		jQuery(document).on('click', '.dropdown-toggle__profile', function () {
			jQuery(this).attr("aria-expanded", "false");
			jQuery(this).siblings('.dropdown-menu').toggleClass('show');

			if (jQuery(this).siblings('.dropdown-menu').hasClass("show")) {
				jQuery(this).attr("aria-expanded", "true");

				jQuery('body').on('click', function (e) {
					if (!jQuery('.dropdown-toggle__profile').is(e.target) && jQuery('.dropdown-toggle__profile').has(e.target).length === 0 && jQuery('.show').has(e.target).length === 0) {
						jQuery('.dropdown-toggle__profile').siblings('.dropdown-menu').removeClass("show");
						jQuery('.dropdown-toggle__profile').attr("aria-expanded", "false");
					}
				});
			}
		});

		// Hide Profile menu on scroll
		jQuery(window).scroll(function () {
			if (jQuery('.navbar-profile .dropdown-menu').hasClass('show')) {
				jQuery('.navbar-profile .dropdown-menu').removeClass("show");
				jQuery('.navbar-profile .dropdown-toggle__profile').attr("aria-expanded", "false");
			}
		});

		// Start - with burger desktop
		jQuery(document).on('click', '.navbar-toggler--desktop', function (e) {
			var $sidemenu = jQuery(".header__intranet .side-menu");
			var $sidemenuToggle = jQuery(".header__intranet .side-menu .side-menu__toggle-mobile");

			// If sidemenu is open
			if ($sidemenu.hasClass('show')) {
				$sidemenu.removeClass('show');
				$sidemenuToggle.removeClass('show');
				jQuery("body").removeClass("position-fixed");

				if ($sidemenuToggle.hasClass('show')) {
					$sidemenuToggle.attr("aria-expanded", "true");
				} else {
					$sidemenuToggle.attr("aria-expanded", "false");
				}
			}

			jQuery('nav').toggleClass("show-burger-desktop");
			jQuery('.navbar-toggler-icon').toggleClass('open');
			jQuery('.navbar-collapse--desktop').toggleClass('show');
			jQuery('body').toggleClass('burger-open');
			jQuery(".header__megamenu-extended__search-bar").slideUp();
			e.preventDefault();

			if (jQuery('.navbar-toggler-icon').hasClass('open')) {
				jQuery('.navbar__search-toggle').hide();
				// disableBodyScroll(navbar);
			} else {
				jQuery('.navbar__search-toggle').show();
				// enableBodyScroll(navbar);
			}

			// Return dropdown menu to original state
			jQuery('.header__intranet .navbar-collapse--desktop .dropdown-menu__megamenu').removeClass('show');
			jQuery('.header__intranet .navbar-collapse--desktop .dropdown-submenu').removeClass('show');
			jQuery('.header__intranet .navbar-collapse--desktop .dropdown-submenu').children('.nav-link').removeClass('show');
			jQuery('.header__intranet .navbar-collapse--desktop .dropdown-submenu').children('.dropdown-menu').slideUp('show');
		});

		// Return to first level menu
		jQuery(document).on('click', '.nav-back-link', function () {

			setTimeout(function () {
				setTimeout(function () {
					closeAccordions()
				}, 200)
				goBack()
			}, 200)

			// Go back function
			function goBack() {
				jQuery('.nav-back-link').closest('.dropdown-menu__megamenu').removeClass('show');
			}

			// Mobile 2nd level dropdowns
			jQuery(document).off('click', '.header__intranet .navbar-collapse--desktop .dropdown-submenu .nav-link');
			jQuery(document).on('click', '.header__intranet .navbar-collapse--desktop .dropdown-submenu .nav-link', function () {
				jQuery(this).toggleClass('show');
				jQuery(this).parents('.header__intranet .navbar-collapse--desktop .dropdown-submenu').toggleClass('show');
				jQuery(this).siblings('.header__intranet .navbar-collapse--desktop .dropdown-menu').slideToggle('show');
			});

			// Close all accordions when returing to 1st level menu - to keep height of menu
			function closeAccordions() {
				jQuery('.header__intranet .dropdown-submenu').removeClass('show');
				jQuery('.header__intranet .dropdown-submenu').children('.nav-link').removeClass('show');
				jQuery('.header__intranet .dropdown-submenu').children('.dropdown-menu').slideUp('show');
			}
		});

		//Mobile Menu toggler
		jQuery(document).on('click', '.navbar-toggler', function (e) {
			var $sidemenu = jQuery(".header__intranet .side-menu");
			var $sidemenuToggle = jQuery(".header__intranet .side-menu .side-menu__toggle-mobile");

			// If sidemenu is open
			if ($sidemenu.hasClass('show')) {
				$sidemenu.removeClass('show');
				$sidemenuToggle.removeClass('show');
				jQuery("body").removeClass("position-fixed");

				if ($sidemenuToggle.hasClass('show')) {
					$sidemenuToggle.attr("aria-expanded", "true");
				} else {
					$sidemenuToggle.attr("aria-expanded", "false");
				}
			}

			jQuery('nav').toggleClass("show-mobile-menu");
			jQuery('.navbar-toggler-icon').toggleClass('open');
			jQuery('body').toggleClass('burger-open');
			jQuery(".header__megamenu-extended__search-bar").slideUp();
			e.preventDefault();

			if (jQuery('.navbar-toggler-icon').hasClass('open')) {
				jQuery('.navbar__search-toggle').hide();
				// disableBodyScroll(navbar);
			} else {
				jQuery('.navbar__search-toggle').show();
				// enableBodyScroll(navbar);
			}

			// Return dropdown menu to original state
			jQuery('.header__intranet .navbar-collapse .dropdown-menu__megamenu').removeClass('show');
			jQuery('.header__intranet .navbar-collapse .dropdown-submenu').removeClass('show');
			jQuery('.header__intranet .navbar-collapse .dropdown-submenu').children('.nav-link').removeClass('show');
			jQuery('.header__intranet .navbar-collapse .dropdown-submenu').children('.dropdown-menu').slideUp('show');
		});

		jQuery(window).on("load resize", function () {

			// Desktop menu
			if (this.matchMedia("(min-width: 991px)").matches) {

				navigationHandler.ensureDesktopMenu();
				var headerWidth = jQuery('.header__intranet').outerWidth(true);
				var navbarWidth = jQuery('.navbar-nav').outerWidth(true);
				var navbarBrandWidth = jQuery('.navbar-brand').outerWidth(true);
				var emptyspace = headerWidth - navbarWidth - navbarBrandWidth;

				var navItems = jQuery('.navbar-nav li.nav-item');
				var navItemBurgerList = jQuery('.navbar-nav__desktop')
				var burgerNavItems = jQuery('.navbar-collapse--desktop li.nav-item');
				var burgerNavLastItem = jQuery('.navbar-collapse--desktop li.nav-item:last');
				var index = 0;

				jQuery(navItems).each(function (i, item) {
					var itemWidth = parseInt(jQuery(this).width());
					jQuery(item).attr('data-nav-item-width', itemWidth);
				});

				jQuery(".dropdown-toggle__mobile").click(function () {
					jQuery(this).siblings('.nav-link.dropdown-toggle').removeAttr('data-toggle', 'dropdown');
					jQuery(this).parent().siblings('.dropdown-menu__megamenu').addClass('show');
					jQuery('.navbar__megamenu-extended.show-mobile-menu').scrollTop(0);
				});

				navigationHandler.checkForSpace(emptyspace, index, navItems, navItemBurgerList, burgerNavItems, burgerNavLastItem, navbarBrandWidth);

				// End - with burger desktop

				// Sicky menu - Headroom
				var headerHeadroom = document.querySelector(".header__intranet");

				var options = {
					// vertical offset in px before element is first unpinned
					offset: 100,
					// callback when pinned, `this` is headroom object
					onPin: function () {
						var currentHeaderHeight = jQuery(header).outerHeight();
						jQuery('progress:not(.progressbar__value)').css('margin-top', currentHeaderHeight);
					},
					// callback when unpinned, `this` is headroom object
					onUnpin: function () {
						jQuery('progress:not(.progressbar__value)').css('margin-top', '0');
						jQuery('.nav-item.dropdown').removeClass('sfHover');
					},
				};

				// construct an instance of Headroom, passing the element
				var headroom = new Headroom(headerHeadroom, options);
				// initialise
				headroom.init();

				// Hover aim - SuperFish
				jQuery('.header__intranet ul.navbar-nav__primary').superfish({
					popUpSelector: 'ul, .dropdown-menu__megamenu',
					// delay: 500, // mouseout delay, ms
					speed: 200, // ms, cref jquery animate()
					//disableHI: true, // disabled because it only works properly if the megamenu is a ul
				});

				jQuery('.header__intranet .dropdown-submenu .dropdown-menu').show();
				jQuery('nav').removeClass("show-mobile-menu");

				// Remove focus on all menu items
				$navLink.focus(
					function () {
						$navLink.closest($dropdown).removeClass(showClass);
						$navLink.find($dropdownToggle).attr("aria-expanded", "false");
						$navLink.closest($dropdown).find($dropdownMenu).removeClass(showClass);
					}
				);

				// Focus on menu items - for web accessibility
				$dropdownToggle.focus(
					function () {
						const $this = jQuery(this);
						$this.closest($dropdown).addClass(showClass);
						$this.attr("aria-expanded", "true");
						$this.closest($dropdown).find($dropdownMenu).addClass(showClass);
					}
				);
			}

			// Mobile menu
			if (this.matchMedia("(max-width: 991px)").matches) {
				navigationHandler.ensureMobileMenu();

				// Mobile 2nd level dropdowns
				jQuery(document).off('click', '.header__intranet .navbar-collapse .dropdown-submenu .nav-link');
				jQuery(document).on('click', '.header__intranet .navbar-collapse .dropdown-submenu .nav-link', function () {
					jQuery(this).toggleClass('show');
					jQuery(this).parents('.header__intranet .navbar-collapse .dropdown-submenu').toggleClass('show');
					jQuery(this).siblings('.header__intranet .navbar-collapse .dropdown-menu').slideToggle('show');
				});

				// Return to first level menu
				jQuery(document).on('click', '.nav-back-link', function () {

					setTimeout(function () {
						setTimeout(function () {
							closeAccordions()
						}, 200)
						goBack()
					}, 200)

					// Go back function
					function goBack() {
						jQuery('.nav-back-link').closest('.dropdown-menu__megamenu').removeClass('show');
					}

					// Close all accordions when returing to 1st level menu - to keep height of menu
					function closeAccordions() {
						jQuery('.header__intranet .dropdown-submenu').removeClass('show');
						jQuery('.header__intranet .dropdown-submenu').children('.nav-link').removeClass('show');
						jQuery('.header__intranet .dropdown-submenu').children('.dropdown-menu').slideUp('show');
					}

				});

				// Disable bootstrap dropdown functionality
				jQuery(".dropdown-toggle__mobile").click(function () {
					jQuery(this).siblings('.nav-link.dropdown-toggle').removeAttr('data-toggle', 'dropdown');
					jQuery(this).parent().siblings('.dropdown-menu__megamenu').addClass('show');
					jQuery('.navbar__megamenu-extended.show-mobile-menu').scrollTop(0);
				});
			}

			// setTimeout(function () {
			// 	var headerHeight = jQuery('header').outerHeight();
			// 	jQuery('main').css("padding-top", headerHeight);
			// }, 200)

		});

	}

}

export default headerIntranet;
