/*
** Smooth scroll
*/

export function smoothScroll() {
	jQuery(document).on("click", "a[href^='#']:not(a[href^='#!']):not(a[href^='#tab'])", function (e) {
    e.preventDefault();
		var position = jQuery(jQuery(this).attr("href")).offset().top - 50;
		jQuery("body, html").animate({
      scrollTop: position
		}, 800);
    window.location.hash = jQuery(this).attr("href");
    // console.log(window.location)
    var id = jQuery(this).attr("href");
    if (jQuery(id).hasClass("js-accordion-item")) {
      jQuery(id).addClass('active')
      jQuery('.js-accordion-item.active').find('> .js-accordion-body').show();
    }
	});

  jQuery(window).on("load", function () {
    var urlHash = window.location.hash.substr(1);
    if(/^[a-zA-Z0-9- ]*$/.test(urlHash) == true) {
      if (urlHash != "" && urlHash != undefined && jQuery('#' + urlHash).length > 0) {
        jQuery('html,body').animate({
          scrollTop: jQuery('#' + urlHash).offset().top - 50
        }, 700);
      }
    }
  });
}

export default smoothScroll;
