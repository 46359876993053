export function ratioCheckerPrefersWhite(background) {
	let TextIsBlack;
	const textColorWhiteHex = '#ffffff';
	const textColorBlackHex = '#000000';

	// Splits rgb() color to a js object
	const rgbToObj = (color) => {
		let colorRgb = ['r', 'g', 'b'];

		let colorValuesArr = color
			.slice(background.indexOf('(') + 1, background.indexOf(')'))
			.split(', ');

		let colorObject = {};

		colorValuesArr.forEach((value, i) => {
			colorObject[colorRgb[i]] = value;
		});

		return colorObject;
	};

	// Accepts a hex-code and extract r, g, b color.
	const hexToRgb = (hex) => {
		let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, function (m, r, g, b) {
			return r + r + g + g + b + b;
		});

		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	};

	const textColorBlackRgb = hexToRgb(textColorBlackHex);
	const textColorWhiteRgb = hexToRgb(textColorWhiteHex);
	// js getComputedStyles returns a rgb
	let bgc = rgbToObj(background);

	// calculate luminance of color
	function luminance(r, g, b) {
		let a = [r, g, b].map(function (v) {
			v /= 255;
			``;
			return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
		});
		return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
	}

	const luminanceBgc = luminance(bgc.r, bgc.g, bgc.b);

	const luminanceTextWhite = luminance(
		textColorWhiteRgb.r,
		textColorWhiteRgb.g,
		textColorWhiteRgb.b
	);

	const luminanceTextBlack = luminance(
		textColorBlackRgb.r,
		textColorBlackRgb.g,
		textColorBlackRgb.b
	);

	const ratioBlackText =
		luminanceBgc > luminanceTextBlack
			? (luminanceTextBlack + 0.05) / (luminanceBgc + 0.05)
			: (luminanceBgc + 0.05) / (luminanceTextBlack + 0.05);

	// Calculate ratio between white and bg-color.
	const ratioWhiteText =
		luminanceBgc > luminanceTextWhite
			? (luminanceTextWhite + 0.05) / (luminanceBgc + 0.05)
			: (luminanceBgc + 0.05) / (luminanceTextWhite + 0.05);

	// Calculate ratio between black and bg-color.


	return ratioBlackText > ratioWhiteText

	const setColorVarableTrue = () => {
		if (TextIsBlack) {
			return;
		}
		TextIsBlack = true;
	};

	const setColorVarableFalse = () => {
		if (!TextIsBlack) {
			return;
		}
		TextIsBlack = false;
	};

	// the smallest ratio becomes the text-color
	ratioBlackText > ratioWhiteText
		? setColorVarableFalse()
		: setColorVarableTrue();

	// add color-class to element with adaptive text-color
	if (!TextIsBlack) {
		document.documentElement.style.setProperty(
			'--changing-text-color',
			'white'
		);

		document.getElementById('logo-id').style.display = 'none'
		document.getElementById('inverse-logo-id').style.display = 'block'
	} else {
		document.documentElement.style.setProperty(
			'--changing-text-color',
			'black'
		);
		document.getElementById('logo-id').style.display = 'block'
		document.getElementById('inverse-logo-id').style.display = 'none'
	}
}

export default ratioCheckerPrefersWhite;
