export function accordion() {
	var accordion = jQuery(".js-accordion");

	if (accordion.length > 0) {
		accordion = (function () {
			// default settings
			var settings = {
				// animation speed
				speed: 400,
				// close all other accordion items if true
				oneOpen: false,
			};

			//site improve disable all accordions
			function isSiteImproveAccordionDisabled() {
				// Check if the value exists in local storage
				const disableValue = localStorage.getItem(
					"siteImproveAccordionDisable",
				);

				// If the value is not present or if it's set to "false", return true (enabled)
				return disableValue === null || disableValue.toLowerCase() !== "false";
			}

			//accordions can be manually disabled by putting a "siteImproveAccordionDisable" key in local storage with the value "false"
			if (!isSiteImproveAccordionDisabled()) {
				return {
					init: function () {
						jQuery(".js-accordion-body").css("display", "block");
					},
				};
			}

			return {
				// pass configurable object literal
				init: function ($settings) {
					jQuery(document).on("click", ".js-accordion-header", function (e) {
						e.preventDefault();

						if (jQuery(this).attr("aria-expanded") == "false") {
							var iframes = jQuery(this)
								.siblings(".accordion-body")
								.find("iframe");
							iframes.each(function (index, element) {
								element.style.maxHeight = "";
							});
						}

						accordion.toggle(jQuery(this));
						// Updated the hash in url and removes it when closed
						var queryString = window.location.search;

						if (
							jQuery(this).parent().hasClass("active") &&
							jQuery(this).parent().attr("id") != undefined
						) {
							// console.log("#" + jQuery(this).parent().attr("id"))
							// window.history.pushState(null, null, window.location.pathname + "#" + jQuery(this).parent().attr("id"));
							window.history.replaceState(
								{},
								"",
								window.location.pathname +
									queryString + // Keep the query string
									"#" +
									jQuery(this).parent().attr("id"),
							);
						} else {
							// window.history.pushState(null, null, window.location.pathname);
							window.history.replaceState({}, "", window.location.pathname +  queryString);
						}
					});

					jQuery(document).on("click", ".js-accordion-toggle-all", function () {
						var $this = jQuery(this).parents(".accordion");
						var $accordionToggleBtn = $this.find(".js-accordion-toggle-all");
						var $textOpen = $accordionToggleBtn.attr("data-textOpen");
						var $textClose = $accordionToggleBtn.attr("data-textClose");
						// $accordionToggleBtn.attr("aria-expanded", "false");

						if ($accordionToggleBtn.hasClass("active")) {
							$accordionToggleBtn.attr("aria-expanded", "false");
							$accordionToggleBtn.find("span").html($textOpen);
							$accordionToggleBtn.removeClass("active");
							$this.find(".js-accordion-header").attr("aria-expanded", "false");
							$this.find(".js-accordion-item").removeClass("active");
							$this.find(".js-accordion-body").stop().slideUp(400);
						} else {
							$accordionToggleBtn.attr("aria-expanded", "true");
							$accordionToggleBtn.find("span").html($textClose);
							$accordionToggleBtn.addClass("active");
							$this.find(".js-accordion-item").addClass("active");
							$this.find(".js-accordion-header").attr("aria-expanded", "true");
							$this.find(".js-accordion-body").stop().slideDown(400);
						}
					});

					$.extend(settings, $settings);

					// ensure only one accordion is active if oneOpen is true
					// if(settings.oneOpen && jQuery('.js-accordion-item.active').length > 1) {
					//     jQuery('.js-accordion-item.active:not(:first)').removeClass('active');
					// }

					// reveal the active accordion bodies
					jQuery(".js-accordion-item.active")
						.find("> .js-accordion-body")
						.show();
				},
				toggle: function ($this) {
					$this.attr("aria-expanded", "false");

					if (
						settings.oneOpen &&
						$this[0] !=
							$this
								.closest(".js-accordion")
								.find("> .js-accordion-item.active > .js-accordion-header")[0]
					) {
						$this
							.closest(".js-accordion")
							.find("> .js-accordion-item")
							// .removeClass('active')
							.find(".js-accordion-body");
						// .slideUp()
					}

					// show/hide the clicked accordion item
					$this.closest(".js-accordion-item").toggleClass("active");
					$this.next().stop().slideToggle(settings.speed);

					if ($this.closest(".js-accordion-item").hasClass("active")) {
						$this.attr("aria-expanded", "true");
					}
				},
			};
		})();

		jQuery(document).ready(function () {
			accordion.init({ speed: 300, oneOpen: true });
		});

		jQuery(document).on(
			"click",
			".accordion-body__contents a:not(.link-box)",
			function (e) {
				e.preventDefault();
				var link = jQuery(this).attr("href");
				// console.log(link)
				var currentPage = window.location.pathname;
				link = link.substring(0, link.indexOf("#"));
				if (currentPage.includes(link) && link != "") {
					var id = jQuery(this).attr("href").split("#")[1];
					if (jQuery("#" + id).hasClass("js-accordion-item")) {
						jQuery("#" + id).addClass("active");
						jQuery(".js-accordion-item.active")
							.find("> .js-accordion-body")
							.show();
						jQuery("html,body").animate(
							{
								scrollTop: jQuery("#" + id).offset().top,
							},
							700,
						);
					}
				} else {
					var target = jQuery(this).attr("target");
					if (target == "_blank") {
						window.open(jQuery(this).attr("href"), "_blank");
					} else {
						window.location.href = jQuery(this).attr("href");
					}
				}
			},
		);

		// Function to open accordion item and scroll to it
		function openAccordionAndScroll(accordionItem) {
			accordionItem
				.addClass("active")
				.find(".js-accordion-body")
				.addClass("active")
				.show();
			var accordionContainer = accordionItem.closest(".js-accordion");
			if (accordionContainer.length > 0) {
				accordionContainer.addClass("active");
				accordionContainer.find("iframe").css({ maxHeight: "none" });
			}
			jQuery("html, body").animate(
				{
					scrollTop: accordionItem.offset().top,
				},
				700,
			);
		}

		jQuery(window).on("load", function () {
			var urlHash = window.location.hash.substring(1);

			if (urlHash) {
				// Check if the URL hash matches any .js-accordion-item
				var targetAccordionItem = jQuery(".js-accordion-item#" + urlHash).not(".js-accordion-item-agenda");
				if (targetAccordionItem.length > 0) {
					openAccordionAndScroll(targetAccordionItem);
				} else {
					// Check if the URL hash matches any anchor link within rich-text content
					var accordionSection = findAccordionSection(urlHash);
					if (accordionSection) {
						openAccordionAndScroll(accordionSection);
					}
				}
			}
		});

		// Listen for clicks on anchor links outside the accordion
		jQuery('.article-anchor-menu a[href^="#"]').on("click", function (e) {
			e.preventDefault();
			var targetId = jQuery(this).attr("href").substring(1); // Get the target anchor ID
			var accordionSection = findAccordionSection(targetId); // Find the accordion section containing the anchor

			if (accordionSection) {
				// Open the accordion if found
				accordionSection.addClass("active");
				accordionSection.find(".js-accordion-body").addClass("active").show();
				// Scroll to the accordion
				jQuery("html,body").animate(
					{
						scrollTop: accordionSection.offset().top,
					},
					700,
				);
			}
		});
	}
}

function findAccordionSection(anchorId) {
	// Find the accordion section containing the given anchor ID
	var accordionSection = null;
	jQuery(".js-accordion-item").each(function () {
		if (jQuery(this).find("#" + anchorId).length > 0) {
			accordionSection = jQuery(this);
			return false; // Break out of the loop
		}
	});
	return accordionSection;
}

export default accordion;
