import checkHolidays from "./checkHolidays";

export function tableHours() {
    if(document.querySelector('.js-table-hours')){
      
      const tablesNodelist = document.querySelectorAll('.js-table-hours')
		const date = new Date();
		const dayNumber = date.getDay();
      let counter = 0;
      const holidays = checkHolidays(date)

      //function that returns true or false to whether current day is within the custom closing period. Takes two dates as argument in format YYYY-MM-DD
      function checkCustomCloseDays(start, end){
        let startDay = new Date(`${start}T00:00:01`)
        let endDay = new Date(`${end}T23:59:59`)
        let today = new Date();
        let startTimestamp = startDay.getTime()
        let endTimestamp = endDay.getTime()
        let currentTimestamp = today.getTime()
        if (currentTimestamp >= startTimestamp && currentTimestamp <= endTimestamp){ return true
        } else { return false }
      }

      //function that sets the strings for custom closed days
      function customCloseDays(day, start, end, indicator){
        let months = [' jan.', ' feb.', ' mar.', ' apr.', ' maj', ' jun.', ' jul.', ' aug.', ' sep.', ' okt.', ' nov.', ' dec.'];
        let endDate = new Date(end);
        let startDate = new Date(start);

        let endDateFormatted = `${endDate.getDate()}. ${months[endDate.getMonth()]} ${endDate.getFullYear()}`
        let startDateFormatted = `${startDate.getDate()}. ${months[startDate.getMonth()]} ${startDate.getFullYear()}`

        //hides normal status message and displays closed status message
        day.parentNode.querySelector('.status').classList.add('hidden')
        indicator.classList.remove('hidden')

        day.parentElement.querySelector('.js-close-start').textContent = startDateFormatted
        day.parentElement.querySelector('.js-close-end').textContent = endDateFormatted

        //check if only closed on one day
        if(start == end || !end){
          let spans = day.parentElement.querySelector('.js-info-close-dates').children
          spans[3].classList.add('hidden')
          spans[4].classList.add('hidden')
          day.parentElement.querySelector('.js-close-start').textContent = `den ${startDateFormatted}`
        }
      }

      //Find next open day function, returns a string with day and opening time
      function FindNextOpenDay(day, dayNumber, closedDay){

        let weekdays = ['mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag', 'søndag']
        let nextOpenDay;
        let nextOpenDayNumber;
        let i = dayNumber;
        let tomorrowStatus;
        let counter = 0;
        let nextOpenDayDate
        
        // get next open day
        const today = new Date()
        today.setDate(today.getDate() + 1)
        i++
        if(i > 6){i = 0}
        
        while((checkHolidays(today).isHoliday || day.parentNode.parentNode.querySelector(`.js-day-${i}`).parentNode.classList.contains('inactive')) && counter < 7){
          today.setDate(today.getDate() + 1);
          i++;
          counter++;
          if(i > 6){
            i = 0
          }
        }      
        //check if next open day is custom close day
        nextOpenDayDate = today.toISOString().substring(0, 10);
        let isClosedDay = nextOpenDayDate == closedDay
          
        //if no holidays, find next open day
/*         else {
          do {
            i++;
            if(i >= 6){
              i = 1
            }
            counter++;
            tomorrowStatus = day.parentNode.parentNode.querySelector(`.js-day-${i}`).parentNode.classList
          }
          while(tomorrowStatus[0].includes('inactive') && counter < 7)            
        } */
  
        nextOpenDay = day.parentNode.parentNode.querySelector(`.js-day-${i}`)
        nextOpenDayNumber = parseInt(nextOpenDay.classList[1].replace('js-day-', ''))
        if(nextOpenDayNumber == 0){ nextOpenDayNumber = 7 }
        if(counter > 6){ return ' ' }
        if(isClosedDay) { return false }
        return `Åbner ${weekdays[nextOpenDayNumber - 1]} kl. ${nextOpenDay.parentNode.querySelector('.open').textContent}`
      }

      // An array of each table with opening hours (corresponds to each week-overview/card)

      let tables = Array.prototype.slice.call(tablesNodelist,0)
      //loop over each table
      tables.forEach(table => {
        let tableDays = table.querySelectorAll('.day')
        //update table if there are holidays in the week (and day is not otherwise a closed day)
        if(holidays.holidaysInCurrentWeek.includes(true)){
          holidays.holidaysInCurrentWeek.forEach((item, index) => {
            if(item && tableDays[index].parentNode.classList.contains('active')){
              tableDays[index].parentElement.classList.add('inactive')
              tableDays[index].parentElement.classList.remove('active')
              tableDays[index].parentElement.querySelector('td').textContent = 'Helligdag'
            }
          })
        }
        
        //find which day is current day and set status message depending on time and parameters
        tableDays.forEach(day => {

          if(day.classList.contains(`js-day-${dayNumber}`) && table.parentNode.querySelector('.status')){
            //Time variables
            let currentDayOpenTime = day.parentNode.querySelector('.open') ? parseFloat(day.parentNode.querySelector('.open').textContent.replace(":", ".")) : false;
            let currentDayClosingTime = day.parentNode.querySelector('.close') ? parseFloat(day.parentNode.querySelector('.close').textContent.replace(":", ".")) : false;
            let currentDayOpenTimeTwo = day.parentNode.querySelector('.openTwo') ? parseFloat(day.parentNode.querySelector('.openTwo').textContent.replace(":", ".")) : false;
            let currentDayClosingTimeTwo = day.parentNode.querySelector('.closeTwo') ? parseFloat(day.parentNode.querySelector('.closeTwo').textContent.replace(":", ".")) : false;
            let busyTimeStart = day.parentNode.querySelector('.js-busy-start')? parseFloat(day.parentNode.querySelector('.js-busy-start').textContent.replace(":", ".")) : false;
            let busyTimeEnd = day.parentNode.querySelector('.js-busy-end') ? parseFloat(day.parentNode.querySelector('.js-busy-end').textContent.replace(":", ".")) : false;
            let currentTime = parseFloat(`${date.getHours()}.${date.getMinutes()}`)

            //Element variables
            let statusIndicatorElement = table.parentNode.querySelector('.status-indicator')
            let statusTextElement = table.parentNode.querySelector('.status-text')
            let statusClosedElement = table.parentNode.querySelector('.status-indicator-closed')
            let statusOpenElement = table.parentNode.querySelector('.status-indicator-open')
            //let busyTimeEndElement = table.parentNode.querySelector('.js-busy-end')

            //Custom closed day variables
            let closedDayIndicator = table.parentElement.querySelector('.js-info-close-dates') ? table.parentElement.querySelector('.js-info-close-dates') : false;
            let closedDayStart = table.parentElement.querySelector('.js-close-start') ? table.parentElement.querySelector('.js-close-start').textContent : false
            let closedDayEnd = table.parentElement.querySelector('.js-close-end') ? table.parentElement.querySelector('.js-close-end').textContent : false

            //Formatted start date
            let months = [' jan.', ' feb.', ' mar.', ' apr.', ' maj', ' jun.', ' jul.', ' aug.', ' sep.', ' okt.', ' nov.', ' dec.'];
            let startDate = new Date(closedDayStart);
            let startDateFormatted = `den ${startDate.getDate()}. ${months[startDate.getMonth()]} ${startDate.getFullYear()}`

            //check if custom closed day
            if(closedDayStart && checkCustomCloseDays(closedDayStart, closedDayEnd)){
              customCloseDays(table, closedDayStart, closedDayEnd, closedDayIndicator)
            }

            //check if closed today
            else if(day.parentNode.classList[0].includes('inactive')){

              if(FindNextOpenDay(day, dayNumber, closedDayStart)){
                statusTextElement.textContent = FindNextOpenDay(day, dayNumber, closedDayStart)
                statusTextElement.classList.remove('hidden')
              } else {
                statusTextElement.textContent = startDateFormatted
              }
              statusIndicatorElement.classList.add('closed-fill')
              statusOpenElement.classList.add('hidden')
              if(FindNextOpenDay(day, dayNumber, closedDayStart) == " "){
                statusTextElement.classList.add('hidden')
              }
            } 

            //check if holiday
            else if(holidays.isHoliday){
              statusIndicatorElement.classList.add('closed-fill')
              if(FindNextOpenDay(day, dayNumber, closedDayStart)){
                statusTextElement.textContent= `Helligdag. Åbner ${FindNextOpenDay(day, dayNumber, closedDayStart)}`
                statusTextElement.classList.remove('hidden')
                statusOpenElement.classList.add('hidden')
              } else {
                statusTextElement.textContent= startDateFormatted
              }
            } 

            //check if open (current time is within opening hours)
            else if(currentDayOpenTime && (currentTime >= currentDayOpenTime && currentTime < currentDayClosingTime)){
              
              //check if within busy time
              if(busyTimeStart && (currentTime >= busyTimeStart && currentTime < busyTimeEnd)){
                statusIndicatorElement.classList.add('busy-fill')
                statusClosedElement.classList.add('hidden')
                statusOpenElement.classList.add('busy')
                statusOpenElement.classList.remove('opened')
                statusTextElement.textContent = `Travlt indtil kl. ${busyTimeEnd}`
              } else {
                //if open and outside busy time
                statusIndicatorElement.classList.add('open-fill')
                statusClosedElement.classList.add('hidden')
                statusTextElement.textContent = `Lukker kl. ${day.parentNode.querySelector('.close').textContent}`
              }
            } 

            //check if open in second open period (current time is within second opening hours)
            else if(currentDayOpenTimeTwo && (currentTime >= currentDayOpenTimeTwo && currentTime < currentDayClosingTimeTwo)){
              //check if within busy time
              if(busyTimeStart && (currentTime >= busyTimeStart && currentTime < busyTimeEnd)){
                statusIndicatorElement.classList.add('busy-fill')
                statusClosedElement.classList.add('hidden')
                statusOpenElement.classList.add('busy')
                statusOpenElement.classList.remove('opened')
                statusTextElement.textContent = `Travlt indtil kl. ${busyTimeEnd}`
              } else {
                //if open and outside busy time
                statusIndicatorElement.classList.add('open-fill')
                statusClosedElement.classList.add('hidden')
                statusTextElement.textContent = `Lukker kl. ${day.parentNode.querySelector('.closeTwo').textContent}`
              }
            } 


            //check if before opening hours (opens later today)
            else if (currentDayOpenTime && (currentTime < currentDayOpenTime)) {
              statusIndicatorElement.classList.add('closed-fill')
              statusOpenElement.classList.add('hidden')
              statusTextElement.textContent = `Åbner kl. ${day.parentNode.querySelector('.open').textContent}`
            }
            //check if between opening hours (opens again later today)
            else if (currentDayOpenTimeTwo && (currentTime < currentDayOpenTimeTwo)) {
              statusIndicatorElement.classList.add('closed-fill')
              statusOpenElement.classList.add('hidden')
              statusTextElement.textContent = `Åbner kl. ${day.parentNode.querySelector('.openTwo').textContent}`
            }
            //else, closed
            else {
              statusIndicatorElement.classList.add('closed-fill')
              if(FindNextOpenDay(day, dayNumber, closedDayStart)){
                statusTextElement.textContent = `${FindNextOpenDay(day, dayNumber, closedDayStart)}`
              } else {
                statusTextElement.textContent = startDateFormatted
              }
              statusOpenElement.classList.add('hidden')
            }
          }
        })
      })
    }
}

export default tableHours;