/*
** Usage: Login if page are locked
*/

export function Login() {

    if (jQuery('.page-login').length == 0) {
		return;
  }

}

export default Login;
