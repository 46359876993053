import cssVars from "css-vars-ponyfill";

export function footer__watermark() {

	if (jQuery(".footer").length < 0) {
		return;
  }

  const watermark = jQuery(".footer .watermark img");

  jQuery(watermark).each(function (i, item) {
    var watermarkMobile = item.getAttribute("data-watermarkMobile");
    var watermarkDesktop = item.getAttribute("data-watermarkDesktop");

    // If has watermark mobile
    if(item.hasAttribute("data-watermarkMobile")) {
      jQuery(window).on("load resize", function () {

        if ((typeof watermarkMobile !== 'undefined' && watermarkMobile !== false) && (typeof watermarkMobile !== 'undefined' && watermarkMobile !== false) || (typeof watermarkMobile !== 'undefined' && watermarkMobile !== false)) {
          // Mobile (Tablet down)
          if (window.matchMedia("(max-width: 991px)").matches) {
            function setMobileWatermark() {
              item.setAttribute("data-src", watermarkMobile);
              item.setAttribute("src", watermarkMobile);
            }
            setMobileWatermark();
          }

          // Desktop (Tablet up)
          if (window.matchMedia("(min-width: 991px)").matches) {
            function setDesktopWatermark() {
              item.setAttribute("data-src", watermarkDesktop);
              item.setAttribute("src", watermarkDesktop);
            }
            setDesktopWatermark();
          }
        } else {
          return;
        }

      });

    } else {
      return;
    }

  });

}

export default footer__watermark;
