export function infoboxLinksIcon() {
  var accordion = jQuery('.info-box__links__icon');

  if (accordion.length > 0) {
    accordion = (function () {
      var $accordion = jQuery('.js-info-box__links__icon');
      var $accordion_item = $accordion.find('.js-info-box__links__icon__item');
      var $accordion_header = $accordion.find('.js-info-box__links__icon__item__header');
      var $accordion_toggle = $accordion.find('.js-toggle');
      var $accordion_body = $accordion.find('.js-info-box__links__icon__item__body');

      // default settings
      var settings = {
        // animation speed
        speed: 400,
        // close all other accordion items if true
        oneOpen: false
      };

      return {
        // pass configurable object literal
        init: function ($settings) {

          // Add toogle button on Desktop
          if ( !$accordion_header.parent().is( "button" ) ) {
            $accordion_header.wrap( "<button class='js-toggle' aria-expanded='false'></button>"  );
          }

          $accordion_header.parent().off('click');
          $accordion_header.parent().on('click', function (e) {
            e.preventDefault();
            accordion.toggle(jQuery(this));
          });

          $.extend(settings, $settings);

          // reveal the active accordion bodies
          jQuery('.js-info-box__links__icon__item.active').find('> .js-info-box__links__icon__item__body').show();
        },

        toggle: function ($this) {
          // Toggle aria-expanded attribute
          var currentAriaExpanded = $this.attr("aria-expanded");
          var newAriaExpanded = currentAriaExpanded === "true" ? "false" : "true";
          $this.attr("aria-expanded", newAriaExpanded);

          // show/hide the clicked accordion item
          $this.closest($accordion_item).toggleClass('active');
          $this.next().stop().slideToggle(settings.speed);
        },

        destroy: function () {
          $accordion_header.parent().off('click');

          // styling
          $accordion_item.find('> .js-info-box__links__icon__item__body').hide();
          $accordion_item.removeClass('active');
          // $accordion_item.find('> .js-info-box__links__icon__item__body').css("height", "auto");
          $accordion_item.find('> .js-info-box__links__icon__item__body').css({height: "auto", display: ""});
          $accordion_item.find('> .js-info-box__links__icon__item__body').show();

          // Remove toogle button on Desktop
          if ($accordion_header.parent().is( "button" )) {
            $accordion_header.unwrap();
          }
        }
      }
    })();

    // var viewportWidth = jQuery(window).width();

    jQuery(window).on("load resize", function () {

      if (this.matchMedia("(max-width: 991px)").matches) {
        accordion.init({
          speed: 300,
          oneOpen: true
        });
      }

      if (this.matchMedia("(min-width: 991px)").matches) {
        accordion.destroy();
      }

    });

  }

}

export default infoboxLinksIcon;
