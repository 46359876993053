/*
** In text arrow link
**
** .arrow can be added to a p tag, to hightlight the p text.
** When link is present inside p tag, the arrow moves on link hover.
*/


export function intextArrowLink() {

  if (jQuery('.intext-arrow-link').length < 0) {
		return;
  }

  // In .rich-text component
  jQuery(".intext-arrow-link a").on({mouseenter: function () {
    jQuery(this).parent('.intext-arrow-link').addClass('onHoverJS');
    },
    mouseleave: function () {
      jQuery(this).parent('.intext-arrow-link').removeClass('onHoverJS');
    }
  });

  // In .intext-arrow-link component
  jQuery(".intext-arrow-link p a").on({mouseenter: function () {
    var parent = jQuery(this).parents('p');
    jQuery(parent).parent('.intext-arrow-link').addClass('onHoverJS');
    },
    mouseleave: function () {
      var parent = jQuery(this).parents('p');
      jQuery(parent).parent('.intext-arrow-link').removeClass('onHoverJS');
    }
  });

}

export default intextArrowLink;
