/**
 * Modal edit
 */

export function modalPopup() {
  if (jQuery(".modal-popup").length == 0) {
		return;
  }

  jQuery('#editLinksForm').validator();

  jQuery(".btn__modal-popup").on("click", function (e) {
    e.preventDefault();
    var $dataId = jQuery(this).attr("data-id");

    jQuery('.modal-popup').each(function (i, item) {
      var $id = jQuery(item).attr("id");

      if ($dataId === $id) {
        jQuery('#' + $dataId).modal("show");
      }
    })
	});

  // Close modal when close btns are clicked
  // jQuery("button.close").on("click", function () {
  //   setTimeout(function () {
	// 		jQuery('.subscription-body').css("margin-top", "0px");
	// 		jQuery('.subscription-body').removeClass("modal-body__hidden");
	// 		jQuery('.message-body').addClass("modal-body__hidden")
  //   }, 1000);
  // });

}

export default modalPopup;
