/*
** Video background
*/

export function videoBackground() {
  // jQuery(".background-video video").controls = false;

  jQuery(".background-video video, button.background-video__btn").click(function () {

    if (!jQuery(this).hasClass('background-video__btn')) {
      var video = this;
      var playBtn = jQuery(this).siblings('button.background-video__btn');

      if (video.paused) {
        video.play();
        playBtn.removeClass('paused');
        playBtn.attr('aria-label', 'Stop afspilning af decorativ video');
      } else {
        video.pause();
        playBtn.addClass('paused');
        playBtn.attr('aria-label', 'Start afspilning af decorativ video');
      }
    } else {
      video = jQuery(this).siblings('video');
      jQuery(this).addClass('paused');

      if (video[0].paused) {
        video[0].play();
        jQuery(this).removeClass('paused');
        playBtn.attr('aria-label', 'Stop afspilning af decorativ video');
      } else {
        video[0].pause();
        jQuery(this).addClass('paused');
        playBtn.attr('aria-label', 'Start afspilning af decorativ video');
      }
    }
  });

}

export default videoBackground;
