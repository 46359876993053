export function videoDreambroker() {
  if (jQuery(".video__dreambroker").length === 0) {
    return;
  }

  jQuery(".video__dreambroker").each(function(index, element) {
    let video = document.createElement("video");
    let src = jQuery(element).data("embed");

    try {
      const parsedUrl = new URL(src);
      const searchParams = parsedUrl.searchParams;

      const loop = searchParams.get("loop");
      const quality = searchParams.get("quality");
      const autoplay = searchParams.get("autoplay");
      const baseUrl = `${parsedUrl.origin}${parsedUrl.pathname}`;

      video.style.visibility = "hidden";
      video.setAttribute('controls', '');

      if (autoplay === "true") {
        video.setAttribute('autoplay', "");
      }

      video.setAttribute('loop', loop === "true" ? 'true' : 'false');

      // console.log('Dreambroker', baseUrl, quality, autoplay, loop);

      // Set the video source
      video.src = `${baseUrl}/get/normal.mp4?autoplay=${autoplay === "true" ? 'true' : 'false'}&lang=da&${quality != null ? "quality=" + quality : "quality=1080p"}&loop=${loop === "true" ? 'true' : 'false'}`;
      // console.log('Dreambroker', video.src);

      var source = document.createElement('source');
      source.setAttribute('src', `${baseUrl}/get/normal.mp4`);
      source.setAttribute('type', 'video/mp4');
      video.appendChild(source);

      // Append the video element to the container without removing the overlay
      jQuery(element).append(video);
    } catch (e) {
      console.error("Error parsing Dreambroker URL: ", e);
    }
  });
}

export default videoDreambroker;
