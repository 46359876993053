export function moveNavItems() {
	const header = document.querySelector(".header--transparent");

	const onMount = () => {
		let topNavBar = jQuery(".header-items__top-nav__list")[0];
		const navbarLogo = jQuery(".navbar-brand")[0];
		const BurgerButton = jQuery(".burger-button");

		// used to calculate the width between logo and burger
		let navbarRight = jQuery(navbarLogo).offset().left + jQuery(navbarLogo).outerWidth();
		jQuery(BurgerButton).css("width", `${jQuery(navbarLogo).outerWidth()}px`);
		let burgerButtonLeft = jQuery(BurgerButton).offset().left;
		jQuery(navbarLogo).css("min-width", `${jQuery(navbarLogo).outerWidth()}px`);

		// allocated space for the navbar
		let width;

		width = Math.round(burgerButtonLeft - navbarRight - 400);

		let root = document.documentElement;

		root.style.setProperty("--mid-width", width + "px");

		if (jQuery(".sidemenu__primary__list li").length === 0) {
			jQuery(".sidemenu__primary__list").remove();
		}

		function checkOverflow(el) {
			return el && el.clientWidth < el.scrollWidth;
		}

		// moves the last list item when navbar overflows the allocated width
		function moveLastItem(el) {
			while (checkOverflow(el)) {
				if (jQuery(".sidemenu__primary__list").length === 0) {
					jQuery(".sidemenu__primary").prepend(
						'<ul aria-busy="true" class="sidemenu__primary__list"></ul>'
					);
				}
				BurgerButton && jQuery(BurgerButton).addClass("active");
				jQuery("#my-list li:last-child").addClass("sidemenu__primary__list-item");
				jQuery(".sidemenu__primary__list").prepend(jQuery("#my-list li:last-child"));
				checkOverflow(el);
			}
		}

		// Hide the burger when the side menu is empty
		function hideBurgerIcon() {
			const sideMenuList = document.getElementById("side-menu-list");

			if (
				sideMenuList &&
				sideMenuList.getElementsByTagName("li").length === 0
			) {
				jQuery(BurgerButton).removeClass("active");
			}
		}

		// Move all navbar list items into the side menu on small screens
		const moveAll = () => {
			if (jQuery(".sidemenu__primary__list").length === 0) {
				jQuery(".sidemenu__primary").prepend(
					'<ul class="sidemenu__primary__list"></ul>'
				);
			}

			jQuery(BurgerButton).addClass("active");
			jQuery(".sidemenu__primary__list").prepend(jQuery(".top-nav__list-item"));
			jQuery(".top-nav__list-item").addClass("sidemenu__primary__list-item");
			jQuery(".header-items__top-nav").remove();
			jQuery(".search-form--transparent").addClass("not-showing");
		};

		//
		const responsiveMenu = () => {
			jQuery(window).on("resize", function () {
				const win = jQuery(this);

				if (jQuery(".sidemenu__primary__list li").length === 0) {
					jQuery(".sidemenu__primary__list").remove();
				}

				navbarRight = jQuery(navbarLogo).offset().left + jQuery(navbarLogo).outerWidth();
				burgerButtonLeft = jQuery(BurgerButton).offset().left;
				width = Math.round(burgerButtonLeft - navbarRight - 400);

				root.style.setProperty("--mid-width", width + "px");

				if (win.width() <= 768) {
					moveAll();
				} else {
					jQuery(".search-form--transparent").removeClass("not-showing");
					let elementExist = !!document.querySelector(".header-items__top-nav");

					if (!elementExist) {
						jQuery(
							`<div class="header-items__top-nav" id="my-list-container"><ul class="header-items__top-nav__list" id="my-list"></ul></div>`
						).insertAfter(".navbar-brand");
					}

					topNavBar = jQuery(".header-items__top-nav__list")[0];
					jQuery(".header-items__top-nav__list").prepend(jQuery(".top-nav__list-item"));
					jQuery(".top-nav__list-item").removeClass("sidemenu__primary__list-item");
					moveLastItem(topNavBar);

					hideBurgerIcon();

					// move all if it only shows two list items
					if (
						document.getElementById("my-list") &&
						document.getElementById("my-list").getElementsByTagName("li")
							.length <= 2
					) {
						moveAll();
					}
				}
			});
		};

		const onRender = () => {
			const win = jQuery(window);

			if (
				document.getElementById("my-list") &&
				document.getElementById("my-list").getElementsByTagName("li").length < 3
			) {
				moveAll();
			}
			if (win.width() <= 768) {
				moveAll();
			}
			responsiveMenu();
			hideBurgerIcon();
			moveLastItem(topNavBar);
		};

		onRender();
	};

	header && onMount();
}

export default moveNavItems();
