import { throttle } from 'lodash-es';

export default () => ({
	open: false,
	desktop: false,

	init() {
		const collapseOn = this.$el.getAttribute('data-collapse-on') ? this.$el.getAttribute('data-collapse-on') : false;

		//check if fact box should collapse on all screen sizes
		if(collapseOn == 'all'){
			this.open == false;
		}
		//else, collapse depending on screen size
		else {
			this.desktop = window.matchMedia('(min-width: 767px)').matches;
			this.open = this.desktop;
			if(window.matchMedia('(min-width: 767px)').matches){
				this.$el.classList.add('fact-box--no-collapse-desktop')
			}
		}
    
		window.addEventListener(
			'resize',
			throttle(() => {
				//only change visibility of fact box if collapse is NOT "all"
				if(collapseOn !== 'all') {
					this.desktop = window.matchMedia('(min-width: 767px)').matches;
					this.open = this.desktop;
					if(this.$el.classList.contains('fact-box--no-collapse-desktop') && window.matchMedia('(max-width: 766px)').matches){
						this.$el.classList.remove('fact-box--no-collapse-desktop')
					} else if (!this.$el.classList.contains('fact-box--no-collapse-desktop') && window.matchMedia('(min-width: 767px)').matches) {
						this.$el.classList.add('fact-box--no-collapse-desktop')	
					}
				}

				
			}, 200)
		);
	},
});
