/**
 * Service menu
 */

export function sideMenu() {
  if (jQuery('.side-menu').length < 0) {
    return;
  }

  //clone sidemenu into header - removed
/*   jQuery(window).on("load", function () {
    if (this.matchMedia("(max-width: 991px)").matches) {
      //const header = jQuery(".header__megamenu-extended");

      // Side menu is cloned into the header on mobile
      //jQuery(".side-menu").clone().insertAfter("header .navbar__megamenu-extended");

      // Main padding same size as header - This overrides the padding set in the header.js file
      //var headerHeight = header.outerHeight();
      //jQuery('main').css("padding-top", headerHeight);
    }
  }); */

  jQuery(document).off('click', '.sidemenu__dropdown .dropdown__togglers .dropdown-toggle');
  jQuery(document).on('click', '.sidemenu__dropdown .dropdown__togglers .dropdown-toggle', function (e) {

    var $this = jQuery(this);
    var $thisDropdown = $this.parent('.dropdown__togglers').parent('.dropdown__list-item');

    if($this.hasClass('show')) {

      $this.removeClass("show");
      // $this.attr("aria-expanded", "false");

      $thisDropdown.removeClass('show');
      $this.siblings('.side-menu__toggle').removeClass('show');

      // $thisDropdown.children('.dropdown-menu').stop().slideUp('show');
      $thisDropdown.children('.dropdown-menu').removeClass('show');
    } else {

      $this.addClass("show");
      // $this.attr("aria-expanded", "true");

      $thisDropdown.addClass('show');
      $this.siblings('.side-menu__toggle').addClass('show');

      // $thisDropdown.children('.dropdown-menu').stop().slideDown('show');
      $thisDropdown.children('.dropdown-menu').addClass('show');

    }
  });

  function toggleMobileMenu(e) {
    e.preventDefault();

    jQuery('.header__megamenu-extended__search-bar').slideUp();
    jQuery('.side-menu').toggleClass('show');
    jQuery(this).toggleClass('show');
    // jQuery("body").toggleClass("position-fixed");

    if (jQuery(this).hasClass('show')) {
      jQuery(this).attr("aria-expanded", "true");
    } else {
      jQuery(this).attr("aria-expanded", "false");
    }
  }

  jQuery(window).on("load", function () {
    // Mobile
    if (window.matchMedia("(max-width: 991px)").matches) {
      jQuery('.side-menu__toggle-mobile').on('click', toggleMobileMenu);
    }
  });

  jQuery(window).on("resize", function () {
    // Mobile
    if (window.matchMedia("(max-width: 991px)").matches) {
      jQuery('.side-menu__toggle-mobile').off('click', toggleMobileMenu);
      jQuery('.side-menu__toggle-mobile').on('click', toggleMobileMenu);
    } else {
      // If not in mobile mode, remove the event listener
      jQuery('.side-menu__toggle-mobile').off('click', toggleMobileMenu);
    }
  });
}

export default sideMenu;
