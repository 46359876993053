import { unset } from "lodash";

export function progress() {
	var progress = jQuery('progress:not(.progressbar__value)');
	if (progress.length > 0) {
		var	articleHeight = jQuery('.article-wrapper').height(),
			progressBar = jQuery('progress:not(.progressbar__value)'),
			menuHeight = jQuery('header').height(),
			max, value;

		/* Set the max scrollable area */
		max = articleHeight + 200;
		progressBar.attr('max', max);
		jQuery(document).on('scroll', function () {
			value = jQuery(window).scrollTop() - menuHeight;
			progressBar.attr('value', value);
		});

	}
	window.addEventListener('resize', () => {
		if(progress.length > 0 ){
			if($(window).width() < 769) {
				progress[0].style.top = 0;
			}
		}
	})


}

export default progress;
