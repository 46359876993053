/**
 * Tab list scrollable
 */

export function tabListScrollable() {
  if (jQuery('.tab-list--scrollable').length < 0) {
    return;
  }

  // Web accessibility
  jQuery(document).off('click', '.tab-list--scrollable .js-tabs a');
  jQuery(document).on('click', '.tab-list--scrollable .js-tabs a', function (e) {
    jQuery('.tab-list--scrollable .js-tabs a').attr("aria-expanded", "false");

    if (jQuery(this).hasClass("active")) {
      jQuery(this).attr("aria-expanded", "true");
    }
  });

}

export default tabListScrollable;
