export function videoHTML(){

	if(document.querySelector('.video__html')){
		const videos = document.querySelectorAll('.video__html')

		videos.forEach(element => {
			let video = element.querySelector('video')
			let playButton = element.querySelector('.video__overlay__button')

			playButton.addEventListener('click', () => {
				video.setAttribute('controls', true)
				// console.log(video.paused)
				// if(video.paused){
				// 	video.play()
				// } else if(!video.paused) {
				// 	video.pause()
				// }
			})
		})
	}
}

export default videoHTML;
